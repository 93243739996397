import React, { useState } from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";

import { AllActiveExerciseTypeListingApi } from "src/DAL/exerciseType/exerciseType";

export default function FoodMattersFilters({
  filterData,
  searchSubmitFilter,
  handleClearFilter,
  setFilterData,
  workoutTypes,
}) {
  const [workoutTypesList, setWorkoutTypesList] = useState(workoutTypes);
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  return (
    <>
      <div className="container new-memories px-4">
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="tags-outlined"
            options={workoutTypesList}
            clearOnBlur={true}
            getOptionLabel={(option) => option.title}
            filterSelectedOptions
            value={filterData?.workout_type}
            onChange={(event, newValue) => {
              // setMember(newValue);
              setFilterData((values) => ({
                ...values,
                workout_type: newValue,
              }));
            }}
            className="mui-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Food Type"
                placeholder="Food Type"
                // onChange={handleSearchMember}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label"> Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.status}
            name="status"
            label=" Status"
            onChange={(e) => handleChangeOthers(e.target.value, "status")}>
            <MenuItem value="active">Active</MenuItem>;
            <MenuItem value={"inactive"}>Inactive</MenuItem>;
          </Select>
        </FormControl>

        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}>
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }>
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}>
          Clear All
        </Button>
      </div>
    </>
  );
}
