import { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
// import { subscription_list_with_page_and_plan } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SubscriptionListFilter from "./SubscriptionListFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import Label from "src/components/Label";
import { project_mode, project_type } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import { subscription_list_with_page_and_plan } from "src/DAL/WebsitePages/websitepages";
import { convertToTitleCase, htmlDecode } from "src/utils/convertHtml";
import FilteredChip from "src/components/FilteredChip";
import { countries } from "src/utils/country";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "member_name", label: "Member", alignRight: false },
  { id: "country", label: "Country", alignRight: false },

  { id: "user_type", label: "Type", alignRight: false },

  { id: "page_name", label: "Product", alignRight: false },
  // { id: "plan_title", label: "Plan Title", alignRight: false },
  { id: "stripe_mode", label: "Subscription Mode", alignRight: false },
  {
    id: "created_at",
    label: "Subscription Date",
    alignRight: false,
  },
  // { id: "invoice_date", label: "Next Invoice Date", alignRight: false },
  {
    id: "statusDate",
    label: "Status",
    alignRight: false,
    renderData: (row) => {
      let className = "pending-ticket";
      return (
        <div style={{ display: "flex" }}>
          <Label
            variant="ghost"
            color={
              row.statusDate.includes("Cancels")
                ? "warning"
                : row.statusDate.includes("Trial")
                ? "success"
                : row.statusDate === "Cancelled"
                ? "primary"
                : row.statusDate === "Expired"
                ? "error"
                : "success"
            }>
            {row.statusDate.includes("Cancels") && (
              <AccessTimeIcon
                style={{ marginRight: "5px", width: "18px", height: "18px" }}
              />
            )}
            {row.statusDate}
          </Label>
          {row?.reason && (
            <Tooltip title={row?.reason}>
              <InfoOutlinedIcon
                style={{
                  marginLeft: "1px",
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];

const useStyles = makeStyles(() => ({
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SubscriptionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const EMPTY_FILTER = {
    start_date: new Date(),
    end_date: new Date(),
    sale_page: null,
    payment_plan: null,
    expiry_in: "",
    expired: "all",
    filter:
      project_type == "dev"
        ? "sandBox"
        : project_type != "dev"
        ? "live"
        : "all",
    expiry_name: null,
    search_text: "",
    country: null,
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    let findCountry = countries.find((item) => {
      if (String(item.label) == String(filterStateUpdated.country)) {
        return item;
      }
    });

    setFilterState(filterStateUpdated);
    setFilterState((values) => ({
      ...filterStateUpdated,
      country: findCountry,
    }));
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeOther = (name, value) => {
    setFilterState((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async (filterData) => {
    setIsLoadingSection(true);
    handleCloseFilterDrawer();
    let postData = { ...filterData };

    if (postData.sale_page) {
      postData.sale_page = postData.sale_page._id;
    } else {
      postData.sale_page = "";
    }
    if (postData.country) {
      postData.country = postData.country.code;
    } else {
      postData.country = "";
    }
    if (postData.payment_plan) {
      postData.payment_plan = postData.payment_plan._id;
    } else {
      postData.payment_plan = "";
    }

    // if (postData.expired === false) {
    //   postData.start_date = moment(postData?.start_date).format("YYYY-MM-DD");
    //   postData.end_date = moment(postData?.end_date).format("YYYY-MM-DD");
    //   if (postData.expiry_in !== "custom") {
    //     postData.end_date = moment(postData.start_date, "YYYY-MM-DD")
    //       .add(postData.expiry_in, "days")
    //       .format("YYYY-MM-DD");
    //   }
    // } else {
    //   delete postData.start_date;
    //   delete postData.end_date;
    //   delete postData.expiry_name;
    // }
    const result = await subscription_list_with_page_and_plan(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      let chipData = { ...filterData };
      // if (postData.expired === false) {
      //   let expiry_name = "";
      //   if (chipData.expiry_in !== "custom") {
      //     expiry_name = `Active`;
      //   } else {
      //     expiry_name = `Active`;
      //   }
      //   chipData.expiry_name = expiry_name;
      // }

      if (chipData?.country?.label) {
        chipData.country = chipData?.country?.label;
      }

      delete chipData.start_date;
      delete chipData.end_date;
      delete chipData.search_text;

      setFilterStateUpdated(chipData);
      let subscription_list = [];
      if (result.subscription.length > 0) {
        subscription_list = result.subscription.map((event, index) => {
          let member_name = "a";
          if (event.user_info?.name) {
            member_name =
              event.user_info?.name +
              " (" +
              (event.user_info?.email ? event.user_info?.email : "N/A") +
              ")";
          }

          let transaction_type = "";
          if (event?.sale_page) {
            transaction_type =
              convertToTitleCase(htmlDecode("sale_page (")) +
              (event?.sale_page ? event?.sale_page?.sale_page_title : "") +
              (event?.payment_request
                ? ` | ` + event?.payment_request?.request_title
                : "") +
              (event?.plan ? ` | ` + event?.plan?.plan_title : "") +
              (event?.transaction_request_type
                ? ` | ` + convertToTitleCase(event?.transaction_request_type)
                : "") +
              ")";
          }

          // console.log(transaction_type, "fajdskhkjf");
          let event_plan = event.plan;
          let plan_title = "N/A";
          if (event_plan?.plan_title) {
            plan_title = event_plan?.plan_title;
          }
          let payment_access = event_plan?.payment_access;
          if (plan_title != "N/A") {
            if (event_plan?.is_plan_free) {
              plan_title = plan_title + " (Free)";
            } else if (payment_access == "recursion") {
              plan_title = plan_title + " (Recurring)";
            } else if (payment_access == "onetime") {
              plan_title = plan_title + " (OneTime)";
            } else {
              plan_title = plan_title;
            }
          }
          let statusTab = "Active";
          if (
            event?.cancelation_requested == true ||
            event?.is_cancelled == true
          ) {
            if (
              event?.cancelation_requested == true &&
              event?.is_cancelled == false
            ) {
              let date = moment(event?.subscription_cancelled_date).format(
                "DD MMMM YYYY"
              );
              statusTab = "Cancels at: " + date;
            } else {
              statusTab = "Cancelled";
            }
          } else if (event?.is_on_trial == true) {
            let date = moment(event?.trial_ending_date).format("DD MMMM YYYY");
            statusTab = "Active: Trial Ends at " + date;
          }

          let findCountry = countries.find((item) => {
            if (
              String(item.code) ==
              String(event?.shipping_object?.address?.country)
            ) {
              return item?.label;
            }
          });
          return {
            ...event,
            member_name: member_name,
            // page_name: event.sale_page?.sale_page_title,
            page_name: transaction_type,
            user_type:
              event.user_info?.user_type == "member_user" ? "Member" : "Coach",
            created_at: dd_date_format(event.createdAt),
            invoice_date: event.next_invoice_date
              ? dd_date_format(event.next_invoice_date)
              : "N/A",
            plan_title: plan_title,
            statusDate:
              event?.cancelation_requested == true ||
              event?.is_cancelled == true
                ? statusTab
                : event?.is_on_trial === true
                ? statusTab
                : event.subscription_status === false
                ? "Expired"
                : "Active",
            country: findCountry?.label,
          };
        });
      }

      setSubscriptionsData(subscription_list);
      setTotalCount(result.total_subscription_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    getMembersList(filterState);
    localStorage.setItem("subscription_list_data", JSON.stringify(filterState));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);

    if (!data.expiry_name) {
      data.expiry_in = "";
      // data.expired = "";
      data.start_date = new Date();
      data.end_date = new Date();
    }
    getMembersList(data);
    setFilterState(data);
    localStorage.setItem("subscription_list_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setFilterState(clear_data);
    getMembersList(clear_data);
    localStorage.removeItem("subscription_list_data");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("subscription_list_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data);
  }, [rowsPerPage, page]);

  if (isLoadingSection == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12 mb-3">
            <h2>Subscription List</h2>
          </div>
          <div className="col-lg-6 col-sm-12 mb-3 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenFilterDrawer}>
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        {/* {console.log(
          filterStateUpdated,
          filterState,
          EMPTY_FILTER,
          "dsfhdskfhkjdshfkjdshfkjsadjh"
        )} */}
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterState}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row mt-3">
          {isLoadingSection ? (
            <CircularProgress
              className={classes.loadingSection}
              color="primary"
            />
          ) : (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={subscriptionsData}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
                pagePagination={true}
              />
            </div>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SubscriptionListFilter
            filterState={filterState}
            handleChange={handleChange}
            handleChangeOther={handleChangeOther}
            handleChangeDate={handleChangeDate}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        }
      />
    </>
  );
}
