import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  backdrop: {
    backgroundColor: "white", // Change the color as per your requirement
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalToShowAninfo({ open, setOpen, exercise }) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        scroll="body"
        maxWidth="md"
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={`text-end mt-1 d-flex justify-content-end align-items-center`}
        >
          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {console.log(exercise, "fjhsdkjfjkas")}
            {exercise?.exercise_type == "something_else" ? (
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                placeholder="Description"
                label="Description"
                type="number"
                value={exercise.description}
                name="description"
                multiline
                // rows={3}
                // onChange={(e) => handleChangeDes(e, mainindex)}
                InputLabelProps={{
                  shrink: true,
                  // style: { fontSize: "14px" },
                }}
                InputProps={{
                  // style: { fontSize: "14px" },
                  readOnly: true,
                }}
                sx={{
                  borderRadius: "5px",
                  // "& legend": { display: "none" },
                  // "& fieldset": { top: 0 },

                  my: 2,
                  width: "100%",
                }}
              />
            ) : exercise?.exercise_info?._id?.video_url ? (
              <ReactVideoPlayer url={exercise?.exercise_info?._id?.video_url} />
            ) : exercise?.exercise_info?._id?.gif_url ? (
              <div className="w-100 d-flex justify-content-center">
                <img src={s3baseUrl + exercise?.exercise_info?._id?.gif_url} />
              </div>
            ) : (
              <img
                width="100%"
                src={s3baseUrl + exercise?.exercise_info?.image?.thumbnail_1}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
