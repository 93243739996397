import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import { makeStyles } from "@mui/styles";
import { programme_workout_exercise_info_api } from "src/DAL/programmeWorkout/programmeWorkout";
import no_data_found from "src/assets/images/no_data_found.png";

import RecordNotFound from "src/components/RecordNotFound";
const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  backdrop: {
    backgroundColor: "white", // Change the color as per your requirement
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ModalToShowAnExerciseInfo({ open, setOpen, Id }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [exercise, setExercise] = React.useState("");

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const handleGetInfo = async () => {
    setIsLoading(true);
    setExercise("");
    try {
      console.log("===called"); //called whn progarm calender new workout
      if (Id) {
        let result = await programme_workout_exercise_info_api(Id);

        if (result.code == 200) {
          setExercise(result?.exercise);
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    handleGetInfo();
  }, [open, Id]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        scroll="body"
        maxWidth="md"
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}>
        <div
          className={`text-end mt-1 d-flex justify-content-end align-items-center`}>
          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {isLoading ? (
            <div className="centered">
              <CircularProgress className={classes.loading2} color="primary" />
            </div>
          ) : !exercise ? (
            <DialogContentText id="alert-dialog-slide-description">
              <div className="centered">
                <div>
                  <img className="mx-auto" src={no_data_found} />
                  <p className="mt-3">{`Information Not Found`}</p>
                </div>
              </div>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              {console.log(exercise, "fjhsdkjfjkas")}
              {exercise?.video_url ? (
                <ReactVideoPlayer url={exercise?.video_url} />
              ) : exercise?.gif_url ? (
                <div className="w-100 d-flex justify-content-center">
                  <img src={s3baseUrl + exercise?.gif_url} />
                </div>
              ) : (
                <img
                  width="100%"
                  src={s3baseUrl + exercise?.image?.thumbnail_1}
                />
              )}
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
