import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import DeletedModal from "src/components/ModalPopover/DeleteModal";
import Label from "src/components/Label";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/components/_dashboard/user";
// import UserListHead from "./UserListHead";
// import GoalStatementMenu from "../components/_dashboard/user/GoalStatementMenu";
// import { QuestionListing } from "src/DAL/GoalStatement/GoalStatement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

import {
  deleteProgrammeSectionApi,
  programmeAutoGroupList,
  programmeSectionList,
  deleteProgrammeAutoGroupApi,
} from "src/DAL/Programme/Programme";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { member_login_api, memberListing } from "src/DAL/member/member";
import { coachPortalUrl, project_url, s3baseUrl } from "src/config/config";
import { coachListing, DeleteCoachApi } from "src/DAL/coach/coach";
import { dd_date_format } from "src/utils/constant";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },

  { id: "number", label: "#", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "program", label: "Programme", alignRight: false },
  // { id: "email", label: "Referral ID", alignRight: false },
  // { id: "address", label: "Address", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  // { id: "member", label: "Member", alignRight: false },
  { id: "createdBy", label: "Created By", alignRight: false },

  { id: "CreatedAt", label: "Created At", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.fullName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const USERLIST = [
  {
    id: 1,
    question: "Current Average Working Hours Per Week?",
    avatarUrl: "/static/mock-images/avatars/avatar_3.jpg",
    status: "active",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ConsultantList() {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [UserId, setUserId] = useState(false);

  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getcoachListing = async () => {
    let postData = {
      search_text: filterName,
    };

    try {
      const result = await coachListing(page, rowsPerPage, postData);
      if (result.code === 200) {
        console.log(result, "dfkjsdahaf");
        setData(result.coach_list);
        setIsLoading(false);

        setQuestionData(result.coach_list);

        const data = result.coach_list.map((consultant) => {
          console.log(consultant?.first_name, "consultant.data.team_type");
          return {
            ...consultant,
            id: consultant?._id,
            fullName: consultant?.first_name + " " + consultant?.last_name,
            name: consultant?.first_name,
            lastName: consultant?.last_name,
            status: consultant?.status,
            email: consultant?.email,
            profile: consultant?.image?.thumbnail_1,
            address: consultant?.address,
            type: consultant?.team_type ? consultant?.team_type : "N/A",
            memberCount: consultant?.member_count,
            selected_address: consultant?.address,
            biography: consultant?.biography,
            selected_city: consultant?.city,
            selected_contact_number: consultant?.contact_number,
            main_heading: consultant?.main_heading,
            selected_state: consultant?.state,
            selected_team_type: consultant?.team_type,
            selected_time_zone: consultant?.time_zone,
            contactNumber: consultant?.contact_number,
            refUser: consultant?.affiliate_info,
            created_at: dd_date_format(consultant.createdAt),
            createdBy: consultant.created_by ? consultant.created_by : "Admin",

            // program: consultant.program,
            // goal: member.goal_statement_status,
            // contact_number: member.contact_number,
            // street: member.street,
            // city: member.city,
            // zip_code: member.zip_code,
            // state: member.state,
            // selectedCountry: member.country,
            // consultant_id: member?.consultant?._id,
            // time_zone: member.time_zone,
            // member_biography: member.member_biography,
            // profile_image: member.profile_image,
            // first_payment_date: member.first_payment_date,
            // selected_country: member.country,
          };
        });
        console.log(data, "sdjkfhksdhfa");
        setUserList(data);

        setTotalPages(result?.total_pages);
        setTotalCount(result.total_coach_count);

        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const LoginAsMember = async (value) => {
    // setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "coach",
    };

    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${coachPortalUrl}login-by-admin/${result?.token}`);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/coach/edit-coach/${value.id}`, {
      state: value,
    });
  };
  const handleFormList = (value) => {
    navigate(`/coach/List-forms/${value.id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/coach/change-password/${value.id}`, {
      state: value,
    });
  };
  const handleChangeRecording = (value) => {
    navigate(`/consultant/recordings/${value.id}`, {
      state: value,
    });
  };
  const handleChangeGroup = (value) => {
    navigate(`/consultant/groups/${value.id}`, {
      state: value,
    });
  };
  const handleChangePods = (value) => {
    navigate(`/consultant/pods-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeMember = (value) => {
    navigate(`/consultant/member-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeGoalStatement = (value) => {
    navigate(`/consultant/goal-statement-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeEvents = (value) => {
    navigate(`/consultant/events/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteCoachApi(deleteDoc.id);
    if (result.code === 200) {
      getcoachListing();
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/coach/add-coach`);
  };
  const handleNavigateMember = (e) => {
    console.log(e, "eeeeeee");
    navigate(`/coach/add-coach`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    getcoachListing();
  }, [rowsPerPage, page]);

  const MENU_OPTIONS1 = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Coach",
      icon: "basil:login-solid",
      handleClick: LoginAsMember,
    },
    {
      label: "View Forms",
      icon: "fluent:form-48-filled",
      handleClick: handleFormList,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
  ];
  const isUserNotFound = userList.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  // console.log(selected, "How data is working ");
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Coaches</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Coach
            </button>
          </div>
        </div>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}>
          {/* <Typography variant="h4" gutterBottom>
              Goal Statement
            </Typography> */}
        </Stack>

        <Card style={{ overflowX: "auto" }}>
          <div className="d-flex align-items-center justify-content-end">
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onSearch={getcoachListing}
            />
            <button
              onClick={() => getcoachListing()}
              className="small-contained-button me-3 "
              // style={{ height: 32 }}
            >
              Search
            </button>
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {userList.map((row, i) => {
                  const {
                    id,
                    fullName,
                    name,
                    status,
                    email,
                    profile,
                    goal,
                    address,
                    type,
                    memberCount,
                    program,
                    refUser,
                    created_at,
                    createdBy,
                    coach_type,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}>
                      <TableCell>
                        {
                          // type === "team" ? (
                          <CustomPopoverSection
                            menu={MENU_OPTIONS1}
                            data={row}
                          />
                          // ) : (
                          //   <CustomPopoverSection
                          //     menu={MENU_OPTIONS}
                          //     data={row}
                          //   />
                          // )
                        }
                      </TableCell>
                      <TableCell align="left">
                        {page * rowsPerPage + (i + 1)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={s3baseUrl + profile} />
                          <Typography variant="subtitle2" noWrap>
                            {fullName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {email ? email : "N/A"}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="program-exp">
                        {program && program.length > 0
                          ? program?.map((program) => {
                              return (
                                <>
                                  <p className="mb-1 modalIcon">
                                    {program?.title}
                                  </p>
                                </>
                              );
                            })
                          : "N/A"}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {address ? Capitalize(htmlDecode(address)) : "N/A"}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {Capitalize(coach_type ? coach_type : "N/A")}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                          {memberCount}
                        </TableCell> */}
                      <TableCell className="text-capital">
                        {createdBy}
                      </TableCell>
                      <TableCell>{created_at}</TableCell>

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </>
  );
}
