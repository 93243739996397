import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import { CircularProgress, Chip } from "@mui/material";
//
import { admin_videos_listing } from "src/DAL/HelpingVideos/AdminHelpingVideos";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/RecordNotFound";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  delete_videos_category,
  helping_video_Category_listing_api,
} from "src/DAL/HelpingVideos/AdminHelpingVideosCategory";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AdminVideosCatagories() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [videosCategory, setVideosCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");

  const is_coach = window.location.pathname.includes("coaches_videos_category");
  const handleOpenDelete = (data) => {
    setDeleteData(data);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const programsList = async () => {
    setIsLoading(true);
    let postData = {
      search: "",
      type: is_coach ? 2 : 1,
    };
    const result = await helping_video_Category_listing_api(postData);
    if (result.code == 200) {
      setIsLoading(false);
      setVideosCategory(result.helping_video_categories);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDelete = async () => {
    console.log(deleteData, "dkjfhkjas");
    let result = await delete_videos_category(deleteData?._id);
    if (result.code === 200) {
      handleCloseDelete();
      enqueueSnackbar(result.message, { variant: "success" });
      programsList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavigateDetail = (value) => {
    is_coach
      ? navigate(`/coaches_videos_category/coaches-videos/${value?._id}`, {
          state: { detailValues: value },
        })
      : navigate(`/members_videos_category/members-videos/${value?._id}`, {
          state: { detailValues: value },
        });
  };
  const handleNavigateEdit = (value) => {
    is_coach
      ? navigate(`/coaches_videos_category/edit/${value?._id}`, {
          state: { detailValues: value },
        })
      : navigate(`/members_videos_category/edit/${value?._id}`, {
          state: { detailValues: value },
        });
  };
  useEffect(() => {
    programsList();
  }, [is_coach]);
  const MENU_OPTIONS = (value) => {
    let array = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleNavigateEdit,
      },

      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleOpenDelete,
      },
    ];

    return array;
  };
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <ConfirmationWithDescription
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
        descriotion={"All videos will be deleted inside this Catagory!"}
      />
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>{is_coach ? "Coach" : "Member"} Category</h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() =>
              is_coach
                ? navigate(`/coaches_videos_category/add`)
                : navigate(`/members_videos_category/add`)
            }
            className="small-contained-button"
          >
            Add Videos Category
          </button>
        </div>
        {videosCategory.length > 0 ? (
          videosCategory.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value?.image?.thumbnail_1}
                    className="card-img-top pods-image"
                    alt=""
                    onClick={() => handleNavigateDetail(value)}
                  />
                  <div className="col-2 programmes-menu-option">
                    {/* <CustomPopover menu={MENU_OPTIONS} data={value} /> */}
                    <CustomPopoverSection
                      menu={MENU_OPTIONS(value)}
                      data={value}
                    />
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="h2-heading fw-bold fs-5"
                          onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(value.title)}
                        </div>
                        {!value?.status && (
                          <Chip
                            label={"Inactive"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <p
                      className="programme-card-desc"
                      onClick={() => handleNavigateDetail(value)}
                    >
                      {htmlDecode(value.description)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <RecordNotFound title="Videos Category" />
        )}
      </div>
    </div>
  );
}
