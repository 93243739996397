import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import {
  programmeActiveListing,
  programmeListing,
} from "src/DAL/Programme/Programme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useEffect } from "react";
// import { COMMUNITY_LEVELS } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { memberListing } from "src/DAL/member/member";
import { coachListing } from "src/DAL/coach/coach";
import { useSnackbar } from "notistack";
export default function UserFormFilter({
  filterData,
  handleChange,
  handleIsDateRange,
  handleChangeOthers,
  searchSubmitFilter,
  handleClearFilter,
  setFilterData,
}) {
  const [programsList, setProgramsList] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [choachesName, setChoachesName] = React.useState(null);
  const [coachesListing, setCoachesListing] = React.useState([]);

  const [serchText, setSerchText] = useState("");
  const [serchTextMember, setSerchTextMember] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [member, setMember] = React.useState(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const handleSearchMember = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextMember(value);
  };
  const getMemberList = (data) => {
    console.log(data, "sdflka");
    let member = data.map((data) => {
      return {
        ...data,
        chip_label: `Member: ${data.first_name}(${data.email})`,
        chip_value: data._id,
      };
    });
    setPersonName(member);
  };
  const getCoachList = (data) => {
    let coach = data.map((data) => {
      return {
        ...data,
        chip_label: `Coach: ${data.first_name}(${data.email})`,
        chip_value: data._id,
      };
    });
    setCoachesListing(coach);
  };
  const getProgList = (data) => {
    let program = data.map((data) => {
      return {
        ...data,
        chip_label: `Program: ${data.title}`,
        chip_value: data._id,
      };
    });
    setProgramsList(program);
  };

  // const handleChangeCommunity = (value) => {
  //   setFilterData((values) => ({ ...values, community: value }));
  // };

  const goalsLevel = [
    {
      name: "Submitted",
      value: "submitted",
    },
    {
      name: "Pending",
      value: "pending",
    },
  ];

  // let community_array = COMMUNITY_LEVELS.map((level) => {
  //   return {
  //     ...level,
  //     chip_label: level.title,
  //     chip_value: level.name,
  //   };
  // });
  const getCoachesAndMembers = async () => {
    let result = {};
    let postData = {
      search_text: serchText,
    };
    if (selectionType == "member") {
      result = await memberListing(0, 60, postData);
    } else {
      result = await coachListing(0, 60, postData);
    }

    if (result.code === 200) {
      // if (!serchText.length) {
      //   setGroupListing(result.group);
      // }

      selectionType == "member"
        ? getMemberList(result?.member)
        : getCoachList(result?.coach_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getPrograms = async () => {
    let result = await programmeActiveListing();
    if (result.code === 200) {
      getProgList(result?.program);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getCoachesAndMembers();
  }, [serchText, selectionType]);
  React.useEffect(() => {
    if (selectionType) {
      setFilterData((values) => ({
        ...values,
        // member: filterData?.member?._id ? filterData?.member : null,
        // coach: filterData?.coach?._id ? filterData?.coach : null,
        selectionType: selectionType,
      }));
    }
  }, [selectionType]);
  searchSubmitFilter;
  const searchSubmitFilterFunc = () => {
    if (filterData.is_date_range) {
      if (filterData.from_date && !filterData.to_date) {
        enqueueSnackbar("please add end date ", { variant: "error" });
      } else if (!filterData.from_date && filterData.to_date) {
        enqueueSnackbar("please add start date ", { variant: "error" });
      }
    } else searchSubmitFilter();
  };
  useEffect(() => {
    getPrograms();
    console.log(filterData, "dsjkfhakj");

    // setSelectionType(
    //   filterData?.coach?._id
    //     ? "coach"
    //     : filterData?.member?._id
    //     ? "member"
    //     : filterData?.receiver_type
    //     ? filterData?.receiver_type
    //     : ""
    // );selectionType
    setSelectionType(filterData.selectionType);
    setFilterData((values) => ({
      ...values,
      is_date_range: filterData.from_date
        ? true
        : filterData.to_date
        ? true
        : false,
    }));
  }, []);
  return (
    <>
      <div className="container new-memories px-4">
        <FormControl fullWidth className="mt-3" required>
          <InputLabel id="demo-simple-select-label">Form type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="Form type"
            value={selectionType}
            label="Form type"
            onChange={(e) => setSelectionType(e.target.value)}
          >
            <MenuItem value="coach">Coach</MenuItem>
            <MenuItem value="member"> Member</MenuItem>
            <MenuItem value="program">Program</MenuItem>
          </Select>
        </FormControl>
        {selectionType == "coach" ? (
          <FormControl fullWidth className="mt-3">
            <Autocomplete
              id="tags-outlined"
              options={coachesListing}
              clearOnBlur={true}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              filterSelectedOptions
              value={filterData.coach}
              onChange={(event, newValue) => {
                // setChoachesName(newValue);
                setFilterData((values) => ({
                  ...values,
                  coach: newValue,
                }));
              }}
              className="mui-autocomplete"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="choaches"
                  placeholder="choaches"
                  onChange={handleSearch}
                />
              )}
            />
          </FormControl>
        ) : selectionType == "program" ? (
          <FormControl fullWidth className="mt-3">
            <Autocomplete
              id="tags-outlined"
              options={programsList}
              clearOnBlur={true}
              getOptionLabel={(option) => option?.title}
              filterSelectedOptions
              value={filterData?.programs_id}
              onChange={(event, newValue) => {
                // setMember(newValue);
                setFilterData((values) => ({
                  ...values,
                  programs_id: newValue,
                }));
              }}
              className="mui-autocomplete"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Programs"
                  placeholder="Programs"
                />
              )}
            />
          </FormControl>
        ) : (
          selectionType == "member" && (
            <FormControl fullWidth className="mt-3">
              <Autocomplete
                id="tags-outlined"
                options={personName}
                clearOnBlur={true}
                getOptionLabel={(option) =>
                  option.first_name + " (" + option.email + ")"
                }
                filterSelectedOptions
                value={filterData.member}
                onChange={(event, newValue) => {
                  // setMember(newValue);
                  setFilterData((values) => ({
                    ...values,
                    member: newValue,
                  }));
                }}
                className="mui-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Members"
                    placeholder="Members"
                    onChange={handleSearchMember}
                  />
                )}
              />
            </FormControl>
          )
        )}
        {/* <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Levels"
            selectedOption={filterData.community}
            setSelectedOption={handleChangeCommunity}
            optionsList={community_array}
            multiple
            name="title"
          />
        </div> */}
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">
            Submition Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.submition_status}
            name="submition_status"
            label="Submition Status"
            onChange={handleChange}
          >
            {goalsLevel?.map((goal) => {
              return (
                <MenuItem value={goal.value} key={goal.value}>
                  {goal.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label"> Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.statuss}
            name="statuss"
            label=" Status"
            onChange={(e) => handleChangeOthers(e.target.value, "statuss")}
          >
            <MenuItem value="active">Active</MenuItem>;
            <MenuItem value={"inactive"}>Inactive</MenuItem>;
          </Select>
        </FormControl>
        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              defaultChecked={
                filterData.from_date ? true : filterData.to_date ? true : false
              }
              checked={filterData.is_date_range}
              onChange={handleIsDateRange}
            />
          }
          label="Search By Date Range"
        />
        {filterData.is_date_range && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="from_date"
                value={filterData.from_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeOthers(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                name="to_date"
                value={filterData.to_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeOthers(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
