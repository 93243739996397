import React, { useEffect, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import MainPart from "./MainPart";
import GroupsFeeds from "./GroupsFeeds";
import GroupsMainPart from "./GroupsMainPart";
import { useNavigate } from "react-router-dom";
import MobilesTabs from "./MobilesTabs";

export default function Feed({ groupFeed, group }) {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { userInfo } = useContentSetting();
  const navigate = useNavigate();

  const handleMyPosts = () => {
    navigate(`/my-posts`);
  };
  return (
    <div className="container new-community-page">
      <div className="row d-none d-lg-flex mt-3">
        <div className="col-lg-6 col-sm-12">
          {groupFeed ? "" : <h2>General Community Area</h2>}
        </div>

        {groupFeed ? (
          ""
        ) : (
          <div className="col-lg-6 col-sm-12 text-end">
            <button className="small-contained-button" onClick={handleMyPosts}>
              {" "}
              My Posts
            </button>
          </div>
        )}
      </div>
      <div className="mobile-case d-block d-lg-none">
        {/* this will be changes on screen size 991 wd */}
        {window.screen.width < 911 && (
          <div className="row mt-3">
            {groupFeed ? (
              <>
                <div className="col-1"></div>
              </>
            ) : (
              <></>
            )}
            <div
              className={`community-main-section pb-4 ${
                groupFeed ? "col-10" : ""
              }`}
            >
              {groupFeed ? (
                <>
                  <GroupsMainPart
                    showInfoBox={showInfoBox}
                    setShowInfoBox={setShowInfoBox}
                    userInfo={userInfo}
                    event_id=""
                    group={group}
                  />
                </>
              ) : (
                <>
                  <MobilesTabs
                    showInfoBox={showInfoBox}
                    setShowInfoBox={setShowInfoBox}
                    userInfo={userInfo}
                    event_id=""
                    handleMyPosts={handleMyPosts}
                  />
                </>
              )}
            </div>
            {groupFeed ? (
              <>
                <div className="col-1"></div>
              </>
            ) : (
              <>
                {/* <div className="col-4 mt-3">
                  <GroupsFeeds />
                </div> */}
              </>
            )}
          </div>
        )}
      </div>
      <div className="row d-none d-lg-flex mt-3">
        {groupFeed ? (
          <>
            <div className="col-1"></div>
          </>
        ) : (
          <></>
        )}
        <div
          className={`community-main-section pb-4 ${
            groupFeed ? "col-10" : "col-8"
          }`}
        >
          {groupFeed ? (
            <>
              <GroupsMainPart
                showInfoBox={showInfoBox}
                setShowInfoBox={setShowInfoBox}
                userInfo={userInfo}
                event_id=""
                group={group}
              />
            </>
          ) : (
            <>
              <MainPart
                showInfoBox={showInfoBox}
                setShowInfoBox={setShowInfoBox}
                userInfo={userInfo}
                event_id=""
              />
            </>
          )}
        </div>
        {groupFeed ? (
          <>
            <div className="col-1"></div>
          </>
        ) : (
          <>
            <div className="col-4">
              <GroupsFeeds />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
