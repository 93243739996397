import React, { useContext, useEffect, useState, useCallback } from "react";
import { _get_user_from_localStorage } from "../DAL/localstorage/LocalStorage";
const CreateContentSetting = React.createContext();
const get_user = _get_user_from_localStorage();
export const useContentSetting = () => useContext(CreateContentSetting);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [userInfo, setUserInfo] = useState(get_user);
  const [userExerciseHistory, setUserExerciseHistory] = useState([]);
  const [userExerciseHistoryLoading, setUserExerciseHistoryLoading] =
    useState(false);
  const [selectedExercise, setSelectedExercise] = useState("");
  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleSetUserInfo = (val) => {
    setUserInfo(val);
  };
  const setUserExerciseHistoryCallback = useCallback((val) => {
    setUserExerciseHistory(val);
  }, []);
  const collection = {
    handleSetUserInfo,
    userInfo,
    setUserInfo,
    userExerciseHistory,
    userExerciseHistoryLoading,
    setUserExerciseHistoryLoading,
    selectedExercise,
    setSelectedExercise,
    setUserExerciseHistory: setUserExerciseHistoryCallback,
  };
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
