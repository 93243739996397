import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { AddMembersFromProgramApi } from "src/DAL/CommunityGroups/CommunityGroups";
import { programmeListing } from "src/DAL/Programme/Programme";
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function AddProgramMembers({
  params,
  handleCloseFilterDrawer,
  getMemberListing,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [programsSelected, setProgramsSelected] = React.useState([]);
  const [programValue, setProgramValue] = React.useState([]);
  const classes = useStyles();

  const programmeListData = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgramValue(result.program);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;
    setProgramsSelected(typeof value === "string" ? value.split(",") : value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let selected_program_array = [];
    let selected_program_object = {};
    programsSelected.map((program) => {
      selected_program_object = {
        _id: program,
      };
      selected_program_array.push(selected_program_object);
    });
    let postData = {
      programs:
        selected_program_array == undefined ? "" : selected_program_array,
    };
    if (selected_program_array.length > 0) {
      setIsLoading(true);

      const result = await AddMembersFromProgramApi(postData, params.slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        getMemberListing();
        handleCloseFilterDrawer();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Programme is Required", { variant: "error" });
    }
  };
  useEffect(() => {
    programmeListData();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container new-memories px-4">
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-multiple-name-label1">Programme *</InputLabel>
          <Select
            labelId="demo-multiple-name-label1"
            id="demo-multiple-name"
            multiple
            value={programsSelected}
            onChange={handleChangeProgram}
            input={<OutlinedInput label="Programme *" />}
            MenuProps={MenuProps}>
            {programValue?.map((name) => (
              <MenuItem key={name._id} value={name._id}>
                {name.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-l2 text-end mt-3">
        <button className="small-contained-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}
