import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";

import { s3baseUrl } from "src/config/config";

export default function AvatarGalleryPreview({ length, Image }) {
  const handleAvatarClick = (path) => {
    if (path) window.open(s3baseUrl + path, "_blank");
  };
  return (
    <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
      {[...Array(length)].map((_, index) => (
        <div key={index} style={{ position: "relative" }}>
          <Avatar
            sx={{ height: 100, width: 100, cursor: "pointer" }}
            alt={`+`}
            src={Image[index] ? s3baseUrl + Image[index] : "+"}
            variant="rounded"
            onClick={() => handleAvatarClick(Image[index])}
          />
        </div>
      ))}
    </div>
  );
}
