import * as React from "react";

import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";

import moment from "moment";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";

import { memberListing } from "src/DAL/member/member";
import { coachListing } from "src/DAL/coach/coach";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { addForm } from "src/DAL/SendFormTemplate/sendFormTemplate";
import { programmeActiveListing } from "src/DAL/Programme/Programme";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allTemplates, setAllTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionType, setSelectionType] = useState("coach");
  const [personName, setPersonName] = React.useState([]);
  const [choachesName, setChoachesName] = React.useState([]);
  const [coachesListing, setCoachesListing] = React.useState([]);
  const [member, setMember] = React.useState([]);
  const [serchText, setSerchText] = useState("");
  const [inputs, setInputs] = useState({
    date: moment().format("YYYY-MM"),
    template_type: false,
    leave_reason: "",
    description: "",
    title: "",
    person: "",
    form_template: "",
  });
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let selected_coach_array = [];
    let selected_member_array = [];
    member.map((member) => {
      selected_member_array.push(member._id);
    });
    choachesName.map((member) => {
      selected_coach_array.push(member._id);
    });
    let postData = {
      title: inputs?.title,
      form_template: inputs?.form_template?._id,
      send_to:
        selectionType == "coach" ? selected_coach_array : selected_member_array,
      receiver_type: selectionType,
      send_all: inputs?.template_type,
      description: inputs?.description,
      program: inputs?.programs_id?._id,
    };
    console.log(postData, "flkjlasfi");
    setIsLoading(true);
    try {
      const res = await addForm(postData);
      if (res.code === 200) {
        navigate(-1);

        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeTemplate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, "asdkjfhsjadfkafsdka");
    setInputs((values) => ({
      ...values,
      [name]: value,
      ["description"]: value.description,
    }));
  };
  const getFormListing = async () => {
    try {
      const result = await getAllTemplatesListByType(selectionType);
      if (result.code === 200) {
        // console.log(result, "dfkjsdahaf");
        setAllTemplates(result?.form_templates);
        setInputs((values) => ({ ...values, form_template: "" }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const getCoachesAndMembers = async () => {
    let result = {};
    let postData = {
      search_text: serchText,
    };
    if (selectionType == "member") {
      result = await memberListing(0, 60, postData);
    } else {
      result = await coachListing(0, 60, postData);
    }

    if (result.code === 200) {
      // if (!serchText.length) {
      //   setGroupListing(result.group);
      // }
      console.log(result, "sdfahsjdha");
      selectionType == "member"
        ? setPersonName(result?.member)
        : setCoachesListing(result?.coach_list);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getCoachesAndMembers();
  }, [serchText, selectionType]);
  React.useEffect(() => {
    getFormListing();
  }, [selectionType]);
  React.useEffect(() => {
    if (inputs.template_type) {
      setChoachesName([]);
      setMember([]);
    }
  }, [inputs.template_type]);
  // React.useEffect(() => {
  //   console.log(inputs?.form_template, "jhdsfka");
  //   setInputs((value) => ({ ...value, description: inputs?.form_template }));
  // }, [inputs?.form_template]);
  const getPrograms = async () => {
    let result = await programmeActiveListing();
    if (result.code === 200) {
      setPersonName(result?.program);
      setInputs((values) => ({
        ...values,
        programs: result?.program,
        programs_id: null,
      }));
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getFormListing();
    getPrograms();
    getCoachesAndMembers();
    // consultantListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Send Form</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            sx={{ width: "100%" }}
            // fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Form type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="Form type"
              value={selectionType}
              label="Form type"
              onChange={(e) => setSelectionType(e.target.value)}
            >
              <MenuItem value="coach">Coach</MenuItem>
              <MenuItem value="member"> Member</MenuItem>
              <MenuItem value="program">Program</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-selec-label">Form Template*</InputLabel>
            <Select
              required
              labelId="demo-simple-selec-label"
              id="demo-simple-select"
              name="form_template"
              value={inputs?.form_template}
              label="Form Template*"
              onChange={handleChangeTemplate}
            >
              {allTemplates.map((item) => (
                <MenuItem key={item._id} value={item}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {selectionType == "program" ? (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <Autocomplete
                id="tags-outlined"
                options={inputs.programs}
                clearOnBlur={true}
                getOptionLabel={(option) => option?.title}
                filterSelectedOptions
                value={inputs?.programs_id}
                onChange={(event, newValue) => {
                  // setMember(newValue);
                  setInputs((values) => ({
                    ...values,
                    programs_id: newValue,
                  }));
                }}
                className="mui-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Programs"
                    placeholder="Programs"
                  />
                )}
              />
            </FormControl>
          </div>
        ) : (
          <>
            {" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Send To All*
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="template_type"
                  value={inputs?.template_type}
                  label="Send To All*"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {selectionType == "coach" ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                {!inputs?.template_type ? (
                  <FormControl className=" w-100">
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={coachesListing}
                      clearOnBlur={true}
                      getOptionLabel={(option) =>
                        option.first_name + " (" + option.email + ")"
                      }
                      filterSelectedOptions
                      value={choachesName}
                      onChange={(event, newValue) => {
                        setChoachesName(newValue);
                      }}
                      className="mui-autocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="choaches"
                          placeholder="choaches"
                          onChange={handleSearch}
                        />
                      )}
                    />
                  </FormControl>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                {!inputs?.template_type ? (
                  <FormControl className=" w-100">
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={personName}
                      clearOnBlur={true}
                      getOptionLabel={(option) =>
                        option.first_name + " (" + option.email + ")"
                      }
                      filterSelectedOptions
                      value={member}
                      onChange={(event, newValue) => {
                        setMember(newValue);
                      }}
                      className="mui-autocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Members"
                          placeholder="Members"
                          onChange={handleSearch}
                        />
                      )}
                    />
                  </FormControl>
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Description"
            multiline
            variant="outlined"
            sx={{ width: "100%" }}
            // fullWidth
            rows={4}
            required
            name="description"
            value={inputs.description}
            onChange={(e) => {
              if (e.target.value[0] === " ") {
                return;
              }
              handleChange(e);
            }}
          />
        </div>
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
