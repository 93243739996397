import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Card,
  Radio,
} from "@mui/material";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

export default function EditTemplate({
  questionsToSend,
  index,
  answerGenius,
  setQuestionsToSend,
  editShow,
  setEditShow,
  setGoToSaveTemplate,
}) {
  const updateTitleChanged = (questionsToSend, index) => (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    questionsToSend[index]["title"] = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };

  const handleChangeQuestionType = (questionsToSend, index) => (e) => {
    questionsToSend[index].type = e.target.value;
    questionsToSend[index].options_count = 0;
    questionsToSend[index].options = ["", ""];
    setQuestionsToSend([...questionsToSend]);
  };
  const handleChangeQuestionTypeRequird = (questionsToSend, index) => (e) => {
    questionsToSend[index].required = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const handleSubmitEditCase = async (e) => {
    e.preventDefault();
    // console.log("skljdfls", index, editShow[index].display);
    editShow[index].display = false;
    setEditShow([...editShow]);
    // setGoToSaveTemplate(true);
  };
  // ------------------------------------

  return (
    <Card
      className="w-100"
      sx={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        mb: 1,
        width: { sm: "100%", md: "100%" },
        margin: "auto",
        p: 2,
      }}
    >
      <form onSubmit={handleSubmitEditCase} autoComplete="off">
        <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            mt={1}
          >
            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-basic"
                label="Question Statement"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="question_statement"
                value={questionsToSend[index].title}
                onChange={updateTitleChanged(questionsToSend, index)}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-selec-label">
                  Question Type*
                </InputLabel>
                <Select
                  size="small"
                  required
                  labelId="demo-simple-selec-label"
                  id="demo-simple-select"
                  name="question_type"
                  value={questionsToSend[index]?.type}
                  label="Question Type*"
                  onChange={handleChangeQuestionType(questionsToSend, index)}
                >
                  <MenuItem value="multiple_choice">
                    Multiple Choice (single select)
                  </MenuItem>
                  <MenuItem value="multiple_select">
                    Multiple Choice (Multiple select)
                  </MenuItem>
                  <MenuItem value="free_text">Text</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="signature">Signature</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl sx={{ mb: 0, width: "100%" }} required>
                <InputLabel id="demo-simple-selec-label1" size="small">
                  Is it Requird?
                </InputLabel>
                <Select
                  size="small"
                  required
                  labelId="demo-simple-selec-label1"
                  id="demo-simple-select"
                  name="question_type_required"
                  value={questionsToSend[index]?.required}
                  label="Is it Requird"
                  onChange={handleChangeQuestionTypeRequird(
                    questionsToSend,
                    index
                  )}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <hr className="mb-0 " />
            </Grid>
            {questionsToSend[index]?.type == "multiple_choice" ? (
              <MultipleChoiceOptions
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
                answerGenius={answerGenius}
              />
            ) : questionsToSend[index]?.type == "multiple_select" ? (
              <MultipleChoiceOptions
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
                answerGenius={answerGenius}
              />
            ) : questionsToSend[index]?.type == "image" ? (
              <RatingView
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
              />
            ) : questionsToSend[index]?.type == "signature" ? (
              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  disabled
                  rows={6}
                  sx={{
                    width: "100%",
                    "& fieldset": {
                      top: 0,
                    },
                    "& legend": {
                      display: "none",
                    },
                  }}
                />{" "}
              </Grid>
            ) : (
              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Answer"
                  multiline
                  disabled
                  rows={6}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}

            <Grid item md={12} xs={12}>
              <hr className="m-0 " />
            </Grid>
          </Grid>

          <div className="col-12 mt-3 text-end">
            <Button
              variant="contained"
              type="submit"
              startIcon={<Icon icon="dashicons:saved" />}
            >
              Save
            </Button>
          </div>
        </Box>
      </form>
    </Card>
  );
}
function RatingView({ questionsToSend, index, setQuestionsToSend }) {
  const updateMinmumLableChanged = (questionsToSend, index) => (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    questionsToSend[index].options[0] = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const updateMaximumLableChanged = (questionsToSend, index) => (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    questionsToSend[index].options[1] = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const updateRatingChanged = (questionsToSend, index) => (e) => {
    questionsToSend[index].options_count = parseInt(e.target.value);
    setQuestionsToSend([...questionsToSend]);
  };
  useEffect(() => {
    questionsToSend[index].options_count = questionsToSend[index].options_count;
    setQuestionsToSend([...questionsToSend]);
    // console.log(questionsToSend[index].options_count, index, "oifjodsfa");
  }, []);
  return (
    <Grid item md={12} xs={12}>
      <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Image Limit:
          </Typography>
        </div>
        <div>
          <FormControl sx={{ mb: 0, width: "100%" }} size="small" required>
            <Select
              required
              labelId="demo-simple-selec-label"
              id="demo-simple-select"
              name="question_type"
              sx={{
                mb: 0,
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={questionsToSend[index].options_count}
              onChange={updateRatingChanged(questionsToSend, index)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>

              <MenuItem value="5">5</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {/* <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Minimum Lable:
          </Typography>
        </div>
        <div className="w-50">
          <TextField
            size="small"
            id="outlined-basic"
            label=""
            variant="outlined"
            sx={{
              width: "100%",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            required
            placeholder="Enter a label for Minimum (please specify)"
            value={questionsToSend[index].options[0]}
            onChange={updateMinmumLableChanged(questionsToSend, index)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Maximum Lable:
          </Typography>
        </div>
        <div className="w-50">
          <TextField
            size="small"
            id="outlined-basic"
            label=""
            variant="outlined"
            sx={{
              width: "100%",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            required
            placeholder="Enter a label for Maximum (please specify)"
            value={questionsToSend[index].options[1]}
            onChange={updateMaximumLableChanged(questionsToSend, index)}
          />
        </div>
      </div> */}
    </Grid>
  );
}

function MultipleChoiceOptions({
  questionsToSend,
  index,
  setQuestionsToSend,
  answerGenius,
}) {
  const [answerGeniusTitleInEdit, setAnswerGeniusTitleInEdit] = useState("");
  const handleChangeInMultipleChoiceEditCase =
    (questionsToSend, indexInChoice) => (e) => {
      if (e.target.value[0] === " ") {
        return;
      }
      questionsToSend[index].options[indexInChoice] = e.target.value;
      setQuestionsToSend([...questionsToSend]);
    };

  const handleChangeAnswerGeniusEditCase =
    (questionsToSend, index) => (event) => {
      setAnswerGeniusTitleInEdit(event.target.value);
      answerGenius.filter((ans) => {
        if (ans.title == event.target.value) {
          questionsToSend[index].options = ans.data;
          setQuestionsToSend([...questionsToSend]);
        }
      });
    };
  const handelAddChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    let list = questionsToSend[index].options;
    list.splice(indexInChoice + 1, 0, "");
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  const handelDelChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    if (questionsToSend[index].options.length === 2) {
      return;
    }
    let list = questionsToSend[index].options;
    list.splice(indexInChoice, 1);
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  return (
    <>
      {/* <Grid item md={12} xs={12}>
        <div className="d-flex align-items-center ">
          <div className="d-flex answer-genius">
            <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
              Answer Genius:
            </Typography>
            <div>
              Just choose the answer type that best matches your question.
            </div>
          </div>
          <div className=" answer-genius-type">
            <FormControl sx={{ mb: 0, width: "100%" }} size="small">
              <InputLabel id="demo-simple-selec-label">Select Type</InputLabel>
              <Select
                labelId="demo-simple-selec-label"
                id="demo-simple-select"
                name="question_type"
                value={answerGeniusTitleInEdit}
                label="Select Type"
                onChange={handleChangeAnswerGeniusEditCase(
                  questionsToSend,
                  index
                )}
              >
                {answerGenius.map((item) => (
                  <MenuItem key={item.data} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Grid>
      <Grid item md={12} xs={12}>
        <hr />
      </Grid> */}
      <Grid item md={12} xs={12}>
        {questionsToSend[index]?.options.map((item, index) => {
          // console.log("item&index", item, index);
          return (
            <div className="d-flex align-items-center mb-2">
              <div>
                <Radio disabled />
              </div>
              <div className="w-100">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  required
                  name="question_statement"
                  placeholder="Enter Choice"
                  value={item}
                  onChange={handleChangeInMultipleChoiceEditCase(
                    questionsToSend,
                    index
                  )}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:add-circle-20-regular"
                  color="#3da5f4"
                  width="30"
                  height="30"
                  onClick={handelAddChoiceEditCase(questionsToSend, index)}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:subtract-circle-28-regular"
                  color="#ff4979"
                  width="30"
                  height="30"
                  onClick={handelDelChoiceEditCase(questionsToSend, index)}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </>
  );
}
