export default function SimpleCustomTextfields({
  label,
  value1,
  value2,
  time,
}) {
  return (
    <div className="small-textfiel-label-box">
      <span className="small-textfiel-outer-label">{label}</span>
      {time ? (
        <div className="d-flex align-items-center justify-content-evenly small-textfiel-main-text">
          <div className="small-textfiel-text ms-1">{value1}</div>
          <div className="small-textfiel-text ms-1">-</div>
          {value2 && <div className="small-textfiel-text2 me-1">{value2}</div>}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between small-textfiel-main-text">
          <div className="small-textfiel-text ms-1">{value1}</div>

          {value2 && <div className="small-textfiel-text2 me-1">{value2}</div>}
        </div>
      )}
    </div>
  );
}
