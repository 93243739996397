import {
  Link as RouterLink,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { pathname } = useLocation();

  if (localStorage.getItem("token") && !pathname.match("/WorkoutDisplay")) {
    return <Navigate to="/programmes"> </Navigate>;
    // return <Navigate to="/dashboard"> </Navigate>;
  }
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
