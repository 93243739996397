import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Autocomplete,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { programmeListing } from "src/DAL/Programme/Programme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useEffect } from "react";
// import { COMMUNITY_LEVELS } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AllActiveExerciseTypeListingApi } from "src/DAL/exerciseType/exerciseType";

export default function ExerciseFilter({
  filterData,
  searchSubmitFilter,
  handleClearFilter,
  setFilterData,
  exercise,
  difficulty,
  main_muscle,
}) {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [exerciseType, setExerciseType] = useState([]);

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };
  const handleTagToggle = (tag) => {
    const newSelectedTags = [...filterData.exercises];

    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }

    setFilterData((prevFilterState) => ({
      ...prevFilterState,
      exercises: newSelectedTags,
    }));
  };
  const handleTagMainMuscleToggle = (tag) => {
    const newSelectedTags = [...filterData.mainMuscle];
    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    setFilterData((prevFilterState) => ({
      ...prevFilterState,
      mainMuscle: newSelectedTags,
    }));
  };
  const handleTagDifficultyToggle = (tag) => {
    const newSelectedTags = [...filterData.difficulty];
    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    setFilterData((prevFilterState) => ({
      ...prevFilterState,
      difficulty: newSelectedTags,
    }));
  };
  // const handleTagToggle = (tag) => () => {
  //   const newSelectedTags = filterData.exercises;
  //   const index = newSelectedTags.indexOf(tag);
  //   if (index === -1) {
  //     newSelectedTags.push(tag);
  //   } else {
  //     newSelectedTags.splice(index, 1);
  //   }
  //   setFilterData((values) => ({
  //     ...values,
  //     ["exercises"]: newSelectedTags,
  //   }));
  // };
  const handleAccordionChange1 = () => {
    setExpanded1(!expanded1);
  };
  const handleAccordionChange2 = () => {
    setExpanded2(!expanded2);
  };
  const handleAccordionChange3 = () => {
    setExpanded3(!expanded3);
  };
  const getExerciseTypes = async () => {
    try {
      const result = await AllActiveExerciseTypeListingApi();
      if (result.code === 200) {
        console.log(result, "dfkjsdahaf");
        setExerciseType(result?.exercise_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getExerciseTypes();
  }, []);
  return (
    <>
      <div className="container new-memories px-4">
        <Accordion expanded={expanded1} onChange={handleAccordionChange1}>
          <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Movement Patterns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              {exercise.map((tag) => (
                <Box key={tag} sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={filterData?.exercises.includes(tag)}
                    onChange={() => handleTagToggle(tag)}
                    sx={{ padding: "5px" }}
                  />
                  <FormControlLabel
                    control={
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        {tag}
                      </Typography>
                    }
                    label=""
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded2} onChange={handleAccordionChange2}>
          <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Muscle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              {main_muscle.map((tag) => (
                <Box key={tag} sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={filterData?.mainMuscle.includes(tag)}
                    onChange={() => handleTagMainMuscleToggle(tag)}
                    sx={{ padding: "5px" }}
                  />
                  <FormControlLabel
                    control={
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        {tag}
                      </Typography>
                    }
                    label=""
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded3} onChange={handleAccordionChange3}>
          <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Difficulty</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              {difficulty.map((tag) => (
                <Box key={tag} sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={filterData?.difficulty.includes(tag)}
                    onChange={() => handleTagDifficultyToggle(tag)}
                    sx={{ padding: "5px" }}
                  />
                  <FormControlLabel
                    control={
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        {tag}
                      </Typography>
                    }
                    label=""
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="tags-outlined"
            options={exerciseType}
            clearOnBlur={true}
            getOptionLabel={(option) => option.title}
            filterSelectedOptions
            value={filterData.exercise_type}
            onChange={(event, newValue) => {
              // setMember(newValue);
              setFilterData((values) => ({
                ...values,
                exercise_type: newValue,
              }));
            }}
            className="mui-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Exercise Type"
                placeholder="Exercise Type"
                // onChange={handleSearchMember}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label"> Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.status}
            name="status"
            label=" Status"
            onChange={(e) => handleChangeOthers(e.target.value, "status")}
          >
            <MenuItem value="active">Active</MenuItem>;
            <MenuItem value={"inactive"}>Inactive</MenuItem>;
          </Select>
        </FormControl>

        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
