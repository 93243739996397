import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import InfoIcon from "@mui/icons-material/Info";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import {
  AddProgrammeApi,
  deleteAudioApi,
  EditProgrammeApi,
  programmeDetailApi,
} from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";
import CustomConfirmationOk from "src/components/ModalPopover/ConfirmationOk";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditProgrammeTemplate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const { programme_slug } = useParams();

  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState("Controlled");
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [oldAudioFile, setOldAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [programmeDetail, setProgrammeDetail] = React.useState([]);
  const [fileNew, setProfileImageNew] = React.useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState();
  const [weeklength, setWeeklength] = React.useState();
  const [allTemplates, setAllTemplates] = useState([]);
  const [lastRecurringDate, setLastRecurringDate] = useState("");
  const [isNumberBefor, setIsNumberBefor] = useState(false);
  const [nextDate, setIsNextDate] = useState(false);

  const [openInfo, setOpenInfo] = useState(false);

  const [inputs, setInputs] = React.useState({
    title: "",
    programStatus: "",
    showStatus: "",
    image: {},
    accessType: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    video_show_Status: "",
    image_show: "",
    no_of_limited_days: "",
    no_of_weeks: 0,
    order: "",
    color: "#0096FF",
    isRecurring: false,
    recurringDays: 0,
    form_template: null,
  });
  // console.log(state, "statestate", inputs?.form_template);
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: `${state}`,
      navigation: `/programmes/programmes-detail/${state}`,
      active: false,
    },
    {
      title: "Edit Programme",
      navigation: null,
      active: true,
    },
  ];
  const handleChangeTemplate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, "asdkjfhsjadfkafsdka");
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };
  const getNavItemList = async () => {
    const result = await menuList();
    if (result.code === 200) {
      setMenuList(result.menus);
      //   setVaultName(result.vault_category[0].vault_slug);
    }
  };
  const getProgrammeDetail = async () => {
    setIsLoading(true);

    try {
      const result = await getAllTemplatesListByType("program");
      if (result.code === 200) {
        setAllTemplates(
          result?.form_templates.map((item) => {
            return {
              title: item?.title,
              _id: item?._id,
            };
          })
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}

    const result = await programmeDetailApi(state?._id);
    if (result.code === 200) {
      console.log(result, "programmeDetail", result?.program?.form_template);
      setProgrammeDetail(result.program);
      setLastRecurringDate(result?.program?.last_form_sent_date);
      setDetailDescriptionCk(result?.program?.detailed_description);
      setInputs((prevState) => ({
        ...prevState,
        ["title"]: result?.program?.title,
        ["short_description"]: result?.program?.short_description,
        ["detailed_description"]: result?.program?.detailed_description,
        ["videoUrl"]: result?.program?.video_url,
        ["programStatus"]: result?.program?.status,
        ["showStatus"]: result?.program?.is_program_show_on_list,
        ["accessType"]: result?.program?.program_length_type,
        ["image_show"]: result?.program?.program_images?.thumbnail_1,
        ["no_of_limited_days"]: result?.program?.no_of_limited_days,
        ["no_of_weeks"]: result?.program?.no_of_weeks,
        ["color"]: result?.program?.color,
        ["order"]: result?.program?.order,
        ["isRecurring"]: result?.program?.form_template ? true : false,
        ["recurringDays"]: result?.program?.recurring_form_days,
        ["form_template"]: result?.program?.form_template,
      }));
      setOldAudio(result?.program?.audio_file);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setProfileImageNew(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handleProgramName = (data) => {
    setProgramName(data.program_slug);
  };
  const handleVaultName = (data) => {
    setVaultName(data.vault_slug);
  };
  const handldeDeleteAudio = async (value) => {
    let postData = {
      status: "audio",
    };
    setIsLoading(true);
    let result = await deleteAudioApi(postData, programme_slug);
    if (result.code == 200) {
      setIsLoading(false);
      getProgrammeDetail();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const membersName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["members"]: membersName,
    }));
  };
  const handleChangeGroups = (event) => {
    const {
      target: { value },
    } = event;
    setGroups(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const groupName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["groups"]: groupName,
    }));
  };
  //console.log(programmeDetail.title, "programmeDetail");

  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      urlPatternValidation(inputs.videoUrl) === false &&
      inputs.videoUrl !== ""
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else if (inputs?.accessType == "limited" && inputs.no_of_weeks < 1) {
        enqueueSnackbar("No of start days at least 1 ", {
          variant: "error",
        });
      } else if (inputs?.isRecurring && inputs.recurringDays < 1) {
        enqueueSnackbar("No of Recurring days at least 1", {
          variant: "error",
        });
        return;
      } else if (inputs?.isRecurring && !inputs.form_template) {
        enqueueSnackbar(`Please select a Form Template`, {
          variant: "error",
        });
        return;
      } else if (
        inputs?.isRecurring &&
        inputs.accessType == "limited" &&
        inputs.recurringDays > inputs.no_of_weeks * 7
      ) {
        enqueueSnackbar(
          ` Enter No of Recurring days between (1 to ${
            inputs.no_of_weeks * 7
          })`,
          {
            variant: "error",
          }
        );
        return;
      } else if (isNumberBefor) {
        enqueueSnackbar(` Please increase form's recurring days`, {
          variant: "error",
        });
        return;
      } else if (fileNew && !inputs.image) {
        enqueueSnackbar("Please upload image", { variant: "error" });
      } else if (detailDescriptionCk == "") {
        enqueueSnackbar("Enter Detail Description", { variant: "error" });
      } else {
        const formData = new FormData();
        formData.append("title", inputs.title);

        formData.append("short_description", inputs.short_description);
        formData.append("detailed_description", detailDescriptionCk);
        formData.append("status", inputs.programStatus);
        formData.append("video_url", inputs.videoUrl);
        // formData.append("program_length_type", inputs.accessType);
        // formData.append("is_program_show_on_list", inputs.showStatus);

        formData.append("nav_items", JSON.stringify(navItems));
        formData.append(
          "no_of_weeks",
          inputs.no_of_weeks ? inputs.no_of_weeks : 0
        );
        // formData.append("is_template", false);
        formData.append("color", inputs.color);
        // formData.append("order", inputs.order);

        if (fileNew == true) {
          formData.append("main_image", inputs.image);
        }
        //console form data
        for (var value of formData.values()) {
          //console.log(value, "form data value");
        }
        console.log(...formData, "formData");

        setIsLoading(true);
        const result = await EditProgrammeApi(formData, programme_slug);
        console.log(programme_slug);
        if (result.code === 200) {
          //console.log(result, "result");
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(`/programme_templats`);
        } else {
          //console.log(result, "error case");
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    getNavItemList();
    getProgrammeDetail();
    setNavitems(state?.nav_items ? state?.nav_items : []);
    //console.log(state.nav_items, "state.nav_items");
  }, []);
  React.useEffect(() => {
    if (inputs.accessType == "limited") {
      return;
    } else if (lastRecurringDate && inputs.recurringDays) {
      try {
        // Create a Moment object for lastRecurringDate
        let momentLastRecurringDate = moment();
        if (lastRecurringDate) {
          momentLastRecurringDate = moment(lastRecurringDate);
        }
        // Add recurring days to the lastRecurringDate
        const formattedDate = momentLastRecurringDate
          .clone() // Clone to avoid modifying original date
          .add(inputs.recurringDays, "days")
          .format("YYYY-MM-DD");

        // Check if the formatted date is before today
        const isBeforeToday = moment(
          formattedDate,
          "YYYY-MM-DD"
        ).isSameOrBefore(moment(), "day");
        setIsNumberBefor(isBeforeToday);
        setIsNextDate(formattedDate);
        console.log(
          "Formatted Date:",
          formattedDate,
          "Is Before Today:",
          isBeforeToday
        );
      } catch (error) {
        console.error("Error occurred while processing dates:", error);
      }
    } else {
      try {
        // Create a Moment object for lastRecurringDate
        let momentLastRecurringDate = moment();
        if (lastRecurringDate) {
          momentLastRecurringDate = moment(lastRecurringDate);
        }
        // Add recurring days to the lastRecurringDate
        const formattedDate = momentLastRecurringDate
          .clone() // Clone to avoid modifying original date
          .add(inputs.recurringDays, "days")
          .format("YYYY-MM-DD");

        // Check if the formatted date is before today

        setIsNextDate(formattedDate);
        console.log("Formatted Date:", formattedDate);
      } catch (error) {
        console.error("Error occurred while processing dates:", error);
      }
    }
  }, [lastRecurringDate, inputs.recurringDays]);

  React.useEffect(() => {
    setDetailDescriptionCk(programmeDetail.detailed_description);
    setWeeklength(programmeDetail.no_of_weeks);
    setInputs((prevState) => ({
      ...prevState,
      ["title"]: programmeDetail.title,
      ["short_description"]: programmeDetail.short_description,
      ["detailed_description"]: programmeDetail.detailed_description,
      ["videoUrl"]: programmeDetail.video_url,
      ["programStatus"]: programmeDetail.status,
      ["showStatus"]: programmeDetail.is_program_show_on_list,
      ["accessType"]: programmeDetail.program_access_type,
      ["image_show"]: programmeDetail.program_images?.thumbnail_1,
      ["no_of_limited_days"]: programmeDetail.no_of_limited_days,
      ["no_of_weeks"]: programmeDetail.no_of_weeks,
      ["order"]: programmeDetail.order,
    }));
    setOldAudio(programmeDetail.audio_file);
  }, [programmeDetail]);

  //console.log(menuLists, "menuListsmenuLists");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmationOk
        open={openInfo}
        setOpen={setOpenInfo}
        title="Please increase number!"
        descriotion={`Your last sent date was ${moment(
          lastRecurringDate
        ).format(
          "YYYY-MM-DD"
        )}. Based on the number of recurring days you entered,  the next scheduled date would be ${nextDate}. Since this date has already passed, please increase the number of recurring days accordingly `}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            // size="small"
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Edit Programme Template</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Programme Name*"
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Video Url "
              variant="outlined"
              fullWidth
              name="videoUrl"
              value={inputs.videoUrl}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Programme Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="programStatus"
                value={inputs.programStatus}
                label="Programme Status *"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Programme Length Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accessType"
                value={inputs.accessType}
                label="Programme Length Type *"
                onChange={handleChange}
                readOnly
              >
                <MenuItem value="limited">Limited</MenuItem>
                <MenuItem value="unlimited">Unlimited</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.accessType == "limited" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="No Of Weeks"
                    variant="outlined"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="No Of Weeks"
                    fullWidth
                    required
                    name="no_of_weeks"
                    value={inputs.no_of_weeks}
                    onChange={handleChange}
                  />
                  {weeklength > inputs.no_of_weeks && (
                    <FormHelperText sx={{ fontSize: 10 }}>
                      {<InfoIcon sx={{ width: 15 }} />} Due to the reduction in
                      length, your workout will be deleted for the items beyond
                      this reduced length.
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              fullWidth
              id="color"
              type="color"
              label="Color"
              name="color"
              variant="outlined"
              required={true}
              value={inputs.color}
              onChange={handleChange}
            />
          </div>

          {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {audioFile && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
        </div> */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-lg-2 col-md-2 col-6">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  <img
                    className="image-border"
                    src={s3baseUrl + inputs.image_show}
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>

          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                rows={6}
                required
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 200 characters</FormHelperText>
            </FormControl>
          </div>
          {/* <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Detail Description *"
              multiline
              rows={6}
              name="detailed_description"
              value={inputs.detailed_description}
              onChange={handleChange}
            />
          </FormControl>
        </div> */}
          <div className="col-12 mt-5">
            <h4>Detail Description *</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
