import PropTypes from "prop-types";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import faker from "faker";
import searchFill from "@iconify/icons-eva/search-fill";

// material
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useCallback, useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";

import { AllExerciseListingApi } from "src/DAL/exercise/exercise";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import ExerciseFilter from "./ExerciseFilterNew";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";
import { UserListToolbar } from "src/components/_dashboard/user";
import { ExerciseTagesApi } from "src/DAL/exerciseType/exerciseType";
import Label from "src/components/Label";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const Lessons = [
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 1: Secrets of Serenity",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula. Freedom is a Mindset. Discover your Purpose",
  },
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 2 - Creative Consciousness",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula. Freedom is a Mindset 2.Discover your Purpose. Success is a Habit. Lead in the New Economy",
  },
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 3: Abundance Accelerator",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula.",
  },
];

// ----------------------------------------------------------------------
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 280, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
    top: 0,
  },
  "& legend": {
    display: "none",
  },
  "@media (max-width: 600px)": {
    width: 240, // Set width to 240 for small screens
    "&.Mui-focused": {
      width: 240, // Set width to 240 when focused on small screens
    },
  },
}));
const tabs = {
  all: "",
  asc: "asc",
  close: "desc",
  trash: "trash",
};
export default function ExercisListing() {
  const id = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [exercise, setExercise] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [oldFilterName, setOldFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [conditionMetOnce, setConditionMetOnce] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterCount, setFilterCount] = useState(0);
  const [exercisetag, setExerciseTag] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [main_muscle, setMainMuscle] = useState([]);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const EMPTY_FILTER = {
    exercises: [],
    mainMuscle: [],
    difficulty: [],
    search_text: "",
    status: "",
    exercise_type: null,
  };
  const [selectedTab, setSelectedTab] = useState(tabs.asc);
  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  // const [filterStateUpdated, setFilterStateUpdated] = useState();
  const [updatedFilter, setUpdatedFilter] = useState(false);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const handleTagToggle2 = (tag) => {
    const newSelectedTags = filterState.exercises;
    // console.log(newSelectedTags, "newSelectedTagsnewSelectedTags");
    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    setFilterState((values) => ({
      ...values,
      ["exercises"]: newSelectedTags,
    }));
  };
  const searchFunction = () => {
    setPage(0);
    // setPageCount(1);
    filterState.search_text = filterName;
    getExerciseList(filterState);
    localStorage.setItem("ExercisesListSearch", JSON.stringify(filterState));
    handleCloseFilterDrawer();
    setIsLoading(true);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    // setPageCount(1);

    getExerciseList(data);
    setFilterState(data);
    // localStorage.setItem("ExercisesListSearch", JSON.stringify(data));
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleClearFilter = () => {
    setExercise([]);
    let clear_data = { ...EMPTY_FILTER };
    // console.log(clear_data, "dslkjfjajsdklfka");
    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getExerciseList(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("ExercisesListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    // console.log(filterStateUpdated, "dkjhkfjhasdjhfasdkfaj", updatedFilter);
    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  // let breadCrumbMenu = [
  //   {
  //     title: "Programmes",
  //     navigation: `/programmes`,
  //     active: false,
  //   },
  //   {
  //     title: `${id.slug}`,
  //     navigation: `/programmes/programmes-detail/${id.slug}`,
  //     active: false,
  //   },
  //   {
  //     title: "Programme Lessons",
  //     navigation: null,
  //     active: true,
  //   },
  // ];
  const fetchMoreData = () => {
    // console.log("Fetching more data...");
    // setRowsPerPage((prev) => prev + 50);
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    if (filterName !== oldFilterName) {
      setOldFilterName(filterName);
    }
  }, [filterName]);
  const getExerciseList = async (filterData) => {
    setOldFilterName(filterName);
    setUpdatedFilter(false);
    let postdata = {
      difficulty: filterData.difficulty,
      exercise: filterData.exercises,
      main_muscle: filterData.mainMuscle,
      exercise_type: filterData?.exercise_type
        ? filterData?.exercise_type._id
        : "",
    };

    let result = await AllExerciseListingApi(
      page,
      rowsPerPage,
      filterName,
      postdata,
      filterData?.status,
      selectedTab
    );
    if (result.code == 200) {
      setUpdatedFilter(true);
      let filtersCount =
        filterData?.difficulty.length +
        filterData?.exercises.length +
        filterData?.mainMuscle.length;
      setFilterCount(
        filterData?.difficulty.length +
          filterData?.exercises.length +
          filterData?.mainMuscle.length
      );
      if (filterData?.status) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }
      if (filterData?.exercise_type) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }

      let chipData = filterData;
      if (chipData.search_text) {
        delete chipData.search_text;
      }

      setFilterStateUpdated(chipData);
      setIsLoading(false);
      const newData = result?.exercises;

      // Check if newData is an array and has data
      setTotalCount(result.total_exercises);
      if (!filtersCount && !filterCount && !filterName) {
        setConditionMetOnce(false);
      }
      if (
        (filtersCount == 1 || filterCount == 1 || filterName.length > 0) &&
        !conditionMetOnce
      ) {
        setExercise(result?.exercises);
        setPage(0);

        setConditionMetOnce(true);
        return;
      } else {
        let mergedData;
        if (page === 0) {
          mergedData = newData;
        } else {
          mergedData = [...exercise, ...newData];
        }
        // const mergedData = [
        //   ...exercise,
        //   ...newData.filter(
        //     (item) =>
        //       !exercise.find((existingItem) => existingItem._id === item._id)
        //   ),
        // ];
        // if (filterName !== oldFilterName) {
        //   console.log("======this is true direct");
        //   mergedData = newData;
        // } else {
        //   console.log("======this is true  not direct");

        //   mergedData = [
        //     ...exercise,
        //     ...newData.filter(
        //       (item) =>
        //         !exercise.find((existingItem) => existingItem._id === item._id)
        //     ),
        //   ];
        // }
        let sortedMergedData = [...mergedData];
        let sortedMergedDataagain = [...mergedData];
        if (selectedTab === "asc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return a.title.localeCompare(b.title);
          });
        } else if (selectedTab === "desc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return b.title.localeCompare(a.title);
          });
        }
        if (filterName) {
          const word = filterName.toLowerCase();
          // console.log(word, "word searched");
          sortedMergedData.sort((a, b) => {
            const startsWithA = a.title.toLowerCase().startsWith(word);
            const startsWithB = b.title.toLowerCase().startsWith(word);

            // If only one of them starts with the search term, that one should come first
            if (startsWithA && !startsWithB) {
              return -1;
            }
            if (!startsWithA && startsWithB) {
              return 1;
            }
            // If neither or both start with the search term, sort alphabetically
            return a.title.localeCompare(b.title);
          });
        }
        if (filterName !== "") {
          setExercise(sortedMergedDataagain);
        } else {
          setExercise(sortedMergedData);
        }
      }

      // setTotalPages(result.total_pages);
    }
  };
  const getExerciseTagesListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = await ExerciseTagesApi();
      if (result.code === 200) {
        setExerciseTag(result?.tags?.exercise);
        setDifficulty(result?.tags?.difficulty);
        setMainMuscle(result?.tags?.main_muscle);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getExerciseTagesListing();
  }, []);
  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("ExercisesListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setFilterName(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    //     getMemberListing(filter_data);
    // filterState.search_text = filterName;
    getExerciseList(filter_data);
  }, [page, rowsPerPage, selectedTab]);
  useEffect(() => {
    // Delay execution of getExerciseList only when filterName changes
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("ExercisesListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      // if (filter_data.search_text) {
      //   setFilterName(filter_data.search_text);
      // }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    const timer = setTimeout(() => {
      setPage(0);
      getExerciseList(filter_data);
    }, 500);

    // Clean up function to clear the timer when filterName changes again or component unmounts
    return () => clearTimeout(timer);
  }, [filterName]);
  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    setExercise([]);
  }, [selectedTab]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  //console.log(id.slug, "id");
  //console.log(lesson, "lessonlessonlesson");
  return (
    <>
      <div className="container">
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <ExerciseFilter
              filterData={filterState}
              setFilterData={setFilterState}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
              exercise={exercisetag}
              difficulty={difficulty}
              main_muscle={main_muscle}
            />
          }
        />
        <div className="row mobile-margin display-flex mt-2">
          <div>
            {/* <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton> */}
            {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}
          </div>
          <div className="col-lg-3 col-sm-12">
            <h2>Exercise </h2>
          </div>
          <div className="col-lg-9 col-sm-12 text-end d-flex justify-content-end align-items-center mt-1 mb-4">
            <div className="d-flex justify-content-end">
              <SearchStyle
                className="ms-auto h-0"
                value={filterName}
                onChange={handleFilterByName}
                placeholder="Search"
                label="Search"
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                }
              />
            </div>
            <button
              className="small-contained-button disPlay-not-sm ms-1 me-1 "
              onClick={handleOpenFilterDrawer}
              style={{ position: "relative" }}>
              Filters &nbsp;&nbsp;{" "}
              <Icon width={19} height={19} icon={roundFilterList} />
              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "20px",
                  right: "9px",
                }}>
                {filterCount ? (
                  <div className="dot-filter-count">{filterCount}</div>
                ) : (
                  <div></div>
                )}
              </span>
            </button>
            <Tooltip className="disPlay-on-sm" title={`Filters`}>
              <button
                className="small-contained-button small-button ms-1 me-1 "
                onClick={handleOpenFilterDrawer}
                style={{ position: "relative" }}>
                <Icon icon={roundFilterList} />
                <span
                  // className="small-contained-button"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "9px",
                  }}>
                  {filterCount ? (
                    <div className="dot-filter-count">{filterCount}</div>
                  ) : (
                    <div></div>
                  )}
                </span>
              </button>
            </Tooltip>

            <button
              className="small-contained-button disPlay-not-sm me-3"
              onClick={() =>
                navigate(`/exercise-add`, {
                  state: id?.slug,
                })
              }>
              Add Exercise
            </button>
            <Tooltip className="disPlay-on-sm" title={`Add Exercise`}>
              <button
                className="small-contained-button me-3"
                onClick={() =>
                  navigate(`/exercise-add`, {
                    state: id?.slug,
                  })
                }>
                +
              </button>
            </Tooltip>
          </div>
        </div>{" "}
        {/* <div className=" text-end">
          <button
            className="small-contained-button"
            onClick={() =>
              navigate(`/add-exercise`, {
                state: id?.slug,
              })
            }
          >
            Add Exercise
          </button>
        </div> */}
        <div className="row section-space">
          {/* <FilteredChip
            data={filterStateUpdated}
            tempState={filterState}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          /> */}

          {/* <div className="col-12">
            <h1>
              {state?.name ? state?.name : lesson[0]?.program[0]?._id?.title}
            </h1>
            <h1>{state.slug}</h1>
          </div> */}
        </div>
        <div className="row mt-4">
          {exercise.length < 1 ? (
            <RecordNotFound title="Exercises" />
          ) : (
            <>
              {" "}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}>
                <div className="d-flex">
                  <div className="me-2 pointer">
                    <Label
                      onClick={() => {
                        if (filterName !== "") {
                        } else {
                          setSelectedTab(tabs.asc);
                        }
                      }}
                      // onClick={() => console.log("fksjdfha")}
                      variant={
                        filterName !== ""
                          ? ""
                          : selectedTab === tabs.asc
                          ? "ghost"
                          : ""
                      }
                      style={{
                        width: 50,
                        cursor: filterName !== "" ? "default" : "pointer",
                      }}
                      color="info">
                      A-Z
                    </Label>
                  </div>
                  <div className="me-2 pointer">
                    <Label
                      onClick={() => {
                        if (filterName !== "") {
                        } else {
                          setSelectedTab(tabs.close);
                        }
                      }}
                      variant={
                        filterName !== ""
                          ? ""
                          : selectedTab === tabs.close
                          ? "ghost"
                          : ""
                      }
                      style={{
                        width: 50,
                        cursor: filterName !== "" ? "default" : "pointer",
                      }}
                      color="info">
                      Z-A
                    </Label>
                  </div>
                </div>
                {/* <Button
            className=""
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Support Ticket
          </Button> */}
              </Stack>
              <InfiniteScroll
                className="w-100 m-auto"
                dataLength={exercise.length}
                next={fetchMoreData}
                hasMore={exercise.length < totalCount ? true : false} // Set this to 'false' when you have loaded all available data.
                loader={<h4 className="text-center">Loading...</h4>}>
                <div className="row w-100 m-auto">
                  {exercise.map((x, i) => {
                    return (
                      <>
                        <div
                          className="col-lg-3 col-md-3 col-sm-12 mb-4"
                          key={i}>
                          <div className="card mt-1 exercise-cards-shadow cursor h-100">
                            <img
                              src={s3baseUrl + x?.image?.thumbnail_1}
                              // src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"
                              className="card-img-top pods-image"
                              alt="workout"
                              onClick={() =>
                                navigate(
                                  `/exercise-details/${x?.exercise_slug}`,
                                  {
                                    state: x,
                                  }
                                )
                              }
                            />
                            <div className="card-body">
                              <div className="row d-flex align-items-end h-100">
                                <div className="col-12 ">
                                  <div
                                    className="h2-heading fw-bold "
                                    // onClick={() => handleNavigateDetail(value)}
                                    onClick={() =>
                                      navigate(
                                        `/exercise-details/${x?.exercise_slug}`,
                                        {
                                          state: x,
                                        }
                                      )
                                    }>
                                    {htmlDecode(x?.title)}
                                  </div>

                                  {!x?.status && (
                                    <Chip
                                      label={"Inactive"}
                                      variant="contained"
                                      color={"error"}
                                      sx={{
                                        fontSize: "10px",
                                        height: "auto",
                                        width: "auto",
                                      }}
                                    />
                                  )}
                                  {/* <span className="InActive px-1">Lessons</span> */}
                                </div>

                                {/* <div className="col-2 menu-option"> */}
                                {/* <CustomPopover menu={MENU_OPTIONS} data={value} /> */}
                                {/* </div> */}
                                {/* <CustomConfirmation
                        asc={openDelete}
                        setOpen={setOpenDelete}
                        title={
                          "Are you sure you want to delete this recording?"
                        }
                        handleAgree={handleDelete}
                      /> */}
                              </div>
                              {/* <p
                          className="programme-card-desc mb-3"
                          // onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(x?.short_description).substring(0, 250) +
                            "..."}
                        </p> */}
                              {/* <p className="programme-card-desc mb-3 programme-card-desc-muted">
                      <b className="text-muted">Programme:</b>{" "}
                      {value.program.title}
                    </p> */}
                              {/* <div className="row recording-card-date">
                        <div className="col-4 card-button recording-card-date-position">
                          <p className="pods-active-members">
                            <span>Lessons</span>
                          </p>
                        </div>
                        <div className="col-8 text-end ms-auto">
                          {value.total_lesson_duration}
                        </div>
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </>
          )}
        </div>
      </div>
    </>
  );
}
