import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { AddMembersFromProgramApi } from "src/DAL/CommunityGroups/CommunityGroups";
import { programmeListing } from "src/DAL/Programme/Programme";
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function RejectReason({ reason, setReason, setOpenReject }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    const value = event.target.value;
    setReason(value);
  };
  const handleSubmit = (e) => {
    if (reason == "") {
      enqueueSnackbar("Reject Reason is Required", { variant: "error" });
    } else {
      setOpenReject(true);
    }
  };
  return (
    <div className="container new-memories px-4">
      {/* <form onSubmit={handleSubmit}>
       
      </form> */}
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <FormControl fullWidth>
          <TextField
            id="outlined-multiline-flexible"
            label="Reject Reason"
            multiline
            rows={6}
            name="reason"
            value={reason}
            onChange={handleChange}
            required
          />
        </FormControl>
      </div>
      <div className="col-l2 text-end mt-3">
        <button className="small-contained-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}
