import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import ModalToShowAninfo from "./ModalToShowAninfo";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import DisplaySet from "./DisplaySet";

const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  backdrop: {
    backgroundColor: "white", // Change the color as per your requirement
  },
}));
export default function AlertDialogSlide({ open, setOpen, exercise }) {
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] =
    React.useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = React.useState("");
  const classes = useStyles();

  const handleClickOpen = (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getMinutesForInterval = (index) => {
    console.log(
      exercise.interval_duration,
      index,
      "dsflaksdjfkljkas",
      exercise
    );
    const interval = exercise.interval_duration;
    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            height: "100%",
          },
        }}
      >
        <ModalToShowAninfo
          open={openSingleExerciseInfo}
          setOpen={setOpenSingleExerciseInfo}
          exercise={singleExerciseInfo}
        />
        <div
          className={`text-end mt-1 d-flex justify-content-end align-items-center`}
        >
          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Card
              key={exercise.id}
              sx={{
                height: "100%",
              }}
              className="base-card"
            >
              <CardContent sx={{ padding: "8px" }}>
                <div key={exercise.id} className="text-start">
                  {exercise.exercise_type === "something_else" && (
                    <>
                      <div
                        className="text-center"
                        style={{
                          color: "#ff7f00",
                          marginBottom: "20px",
                        }}
                      >
                        <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                        Something Else
                      </div>

                      <Card className="exercise-card mb-1 d-flex align-items-center">
                        {/* <div>
                          <Icon icon="ph:tag-simple-fill" className="ms-1" />
                        </div> */}
                        <div className="w-100 p-2">
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            placeholder="Description"
                            label="Description"
                            type="number"
                            value={exercise.description}
                            name="description"
                            multiline
                            // rows={3}
                            // onChange={(e) => handleChangeDes(e, mainindex)}
                            InputLabelProps={{
                              shrink: true,
                              // style: { fontSize: "14px" },
                            }}
                            InputProps={{
                              // style: { fontSize: "14px" },
                              readOnly: true,
                            }}
                            sx={{
                              borderRadius: "5px",
                              // "& legend": { display: "none" },
                              // "& fieldset": { top: 0 },

                              my: 2,
                              width: "100%",
                            }}
                          />
                          {/* <div className="px-2">
                            <Typography variant="subtitle2" component="div">
                              {exercise.description}
                            </Typography>
                          </div> */}
                        </div>
                        {/* <div
                          onClick={() => handleClickOpen(exercise)}
                          style={{
                            cursor: "pointer",
                            color: get_root_value("--portal-theme-primary"),
                          }}
                        >
                          <Icon icon="octicon:info-24" />
                        </div> */}
                      </Card>
                    </>
                  )}

                  {exercise.exercise_type === "amrap" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <div
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          AMRAP
                        </div>
                        {exercise.emom_amrap.map((interval, intervalIndex) => (
                          <div key={intervalIndex}>
                            <div
                              style={{
                                fontSize: "11px",
                                color: "#ff7f00",
                              }}
                            >
                              Movements
                            </div>
                            {interval.map((exerData) => (
                              <div key={exerData.id}>
                                <Card className="exercise-card my-1 ">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Icon
                                        icon="ph:tag-simple-fill"
                                        className="ms-1"
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleClickOpen(exerData)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: get_root_value(
                                              "--portal-theme-primary"
                                            ),
                                          }}
                                        >
                                          <Icon icon="octicon:info-24" />
                                        </div>
                                      </div>
                                      <div className="px-2">
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 10 }}
                                        >
                                          <DisplaySet
                                            sets={exerData?.sets[0]}
                                            parameters={exerData?.parameters}
                                          />
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}

                  {exercise.exercise_type === "emom" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <h4
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          EMOM
                        </h4>
                        {exercise?.emom_amrap?.map(
                          (interval, intervalIndex) => {
                            return (
                              <div className="my-1">
                                <div
                                  style={{
                                    fontSize: "11px",
                                    color: "#ff7f00",
                                  }}
                                >
                                  Minute {getMinutesForInterval(intervalIndex)}
                                </div>
                                {interval?.map((exerData) => {
                                  console.log(exerData, "skdfjhdskja");
                                  return (
                                    <div key={exerData.id}>
                                      <Card className="exercise-card my-1 ">
                                        <div className="d-flex align-items-center">
                                          <div>
                                            <Icon
                                              icon="ph:tag-simple-fill"
                                              className="ms-1"
                                            />
                                          </div>
                                          <div>
                                            <div className="d-flex align-items-center">
                                              <div>
                                                <div className="px-2">
                                                  <Typography
                                                    variant="subtitle2"
                                                    component="div"
                                                  >
                                                    {
                                                      exerData.exercise_info
                                                        .title
                                                    }
                                                  </Typography>
                                                </div>
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleClickOpen(exerData)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: get_root_value(
                                                    "--portal-theme-primary"
                                                  ),
                                                }}
                                              >
                                                <Icon icon="octicon:info-24" />
                                              </div>
                                            </div>
                                            <div className="px-2">
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: 10 }}
                                              >
                                                <DisplaySet
                                                  sets={exerData?.sets[0]}
                                                  parameters={
                                                    exerData?.parameters
                                                  }
                                                />
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  {exercise.sub_exercises && exercise.sub_exercises.length > 0 && (
                    <div>
                      <div
                        className="text-center"
                        style={{
                          color: "#ff7f00",
                          marginBottom: "20px",
                        }}
                      >
                        <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                        Superset
                      </div>
                      {exercise.sub_exercises.map((exerData) => (
                        <div key={exerData.id}>
                          <Card className="exercise-card my-1 ">
                            <div className="d-flex align-items-center">
                              <div>
                                <Icon
                                  icon="ph:tag-simple-fill"
                                  className="ms-1"
                                />
                              </div>
                              <div>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="px-2">
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                      >
                                        {exerData.exercise_info.title}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => handleClickOpen(exerData)}
                                    style={{
                                      cursor: "pointer",
                                      color: get_root_value(
                                        "--portal-theme-primary"
                                      ),
                                    }}
                                  >
                                    <Icon icon="octicon:info-24" />
                                  </div>
                                </div>
                                <div className="px-2">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: 10 }}
                                  >
                                    <DisplaySet
                                      sets={exerData?.sets[0]}
                                      parameters={exerData?.parameters}
                                    />
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    </div>
                  )}
                  {exercise.exercise_type == "general" &&
                    exercise.sub_exercises.length == 0 && (
                      <>
                        <div
                          className="text-center"
                          style={{
                            color: "#ff7f00",
                            marginBottom: "20px",
                          }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          General Exercise
                        </div>
                        <Card className="exercise-card my-1 ">
                          <div className="d-flex align-items-center">
                            <div>
                              <Icon
                                icon="ph:tag-simple-fill"
                                className="ms-1"
                              />
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="px-2">
                                    <Typography
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {exercise.exercise_info.title}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  onClick={() => handleClickOpen(exercise)}
                                  style={{
                                    cursor: "pointer",
                                    color: get_root_value(
                                      "--portal-theme-primary"
                                    ),
                                  }}
                                >
                                  <Icon icon="octicon:info-24" />
                                </div>
                              </div>
                              <div className="px-2">
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: 10 }}
                                >
                                  <DisplaySet
                                    sets={exercise?.sets[0]}
                                    parameters={exercise?.parameters}
                                  />
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </>
                    )}
                </div>
              </CardContent>
            </Card>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
