import { invokeApi } from "../../bl_libs/invokeApi";

export const GymTVProgramsListingApi = async (data) => {
  const requestObj = {
    path: `/api/program_workout/program_workout_detail/for_gym_tv`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
