import { invokeApi } from "../../bl_libs/invokeApi";

export const transactionListApi = async (page, limit, search) => {
  const requestObj = {
    path: `/admin_users/all_transaction_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const deleteTransactionApi = async (id) => {
  const requestObj = {
    path: `/admin_users/delete_transaction/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const transactionListFilterPageRequestApi = async () => {
  const requestObj = {
    path: `/admin_users/sale_page_payment_request_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
