import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Radio,
} from "@mui/material";

import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

export default function MultipleChoiceOptions({
  answerGeniusTitle,
  handleChangeAnswerGenius,
  answerGenius,
  multipleChoiceOptions,
  handleChangeInMultipleChoice,
  handelAddChoice,
  handelDelChoice,
}) {
  // console.log(answerGenius, "sfdklsd");
  return (
    <>
      {/* <Grid item md={12} xs={12}>
        <div className="d-flex align-items-center ">
          <div className="d-flex answer-genius">
            <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
              Answer Genius:
            </Typography>
            <div>
              Just choose the answer type that best matches your question.
            </div>
          </div>
          <div className=" answer-genius-type">
            <FormControl sx={{ mb: 0, width: "100%" }} size="small">
              <InputLabel id="demo-simple-selec-label">Select Type</InputLabel>
              <Select
                // required
                labelId="demo-simple-selec-label"
                id="demo-simple-select"
                name="question_type"
                value={answerGeniusTitle}
                label="Select Type"
                onChange={handleChangeAnswerGenius}
              >
                {answerGenius.map((item) => (
                  <MenuItem key={item.data} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Grid> */}
      {/* <Grid item md={12} xs={12}>
        <hr />
      </Grid> */}
      <Grid item md={12} xs={12}>
        {multipleChoiceOptions.map((item, index) => {
          // console.log("item&index", item, index);
          return (
            <div className="d-flex align-items-center mb-2">
              <div>
                <Radio disabled />
              </div>
              <div className="w-100">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  // fullWidth
                  required
                  name="question_statement"
                  placeholder="Enter Choice"
                  value={item}
                  onChange={(e) => handleChangeInMultipleChoice(e, index)}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:add-circle-20-regular"
                  color="#3da5f4"
                  width="30"
                  height="30"
                  onClick={() => {
                    handelAddChoice(index);
                  }}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:subtract-circle-28-regular"
                  color="#ff4979"
                  width="30"
                  height="30"
                  onClick={() => {
                    handelDelChoice(index);
                  }}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </>
  );
}
