import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { s3baseUrl } from "src/config/config";
import {
  DeleteSubscriberMemberApi,
  memberCancleSubscribergApi,
  memberSubscriberListingApi,
} from "src/DAL/member/member";
import { dd_date_format } from "src/utils/constant";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import Label from "src/components/Label";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",
    alignRight: false,
    MENU_OPTIONS: "MENU_OPTIONS",
    type: "action",
  },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "page_name", label: "Page Title", alignRight: false },
  { id: "plan_title", label: "Plan Title", alignRight: false },
  // { id: "affiliate_name", label: "Referral", alignRight: false },
  // {
  //   id: "preview_link_data",
  //   label: "Agreement PDF",
  //   alignRight: false,
  //   type: "link",
  // },
  {
    id: "created_at",
    label: "Subscription Date",
    alignRight: false,
  },
  {
    id: "nextPaymentDate",
    label: "Next Payment Date",
    alignRight: false,
  },
  {
    id: "statusDate",
    label: "Status",
    alignRight: false,
    renderData: (row) => {
      let className = "pending-ticket";
      return (
        <div style={{ display: "flex" }}>
          <Label
            variant="ghost"
            color={
              row.statusDate.includes("Cancels")
                ? "warning"
                : row.statusDate.includes("Trial")
                ? "info"
                : row.statusDate === "Expired"
                ? "error"
                : "success"
            }>
            {row.statusDate.includes("Cancels") && (
              <AccessTimeIcon
                style={{ marginRight: "5px", width: "18px", height: "18px" }}
              />
            )}

            {row.statusDate}
          </Label>
          {row?.reason && row.statusDate != "Completed" && (
            <Tooltip title={row?.reason}>
              <InfoOutlinedIcon
                style={{
                  marginLeft: "5px",
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  // {
  //   id: "register_url",
  //   label: "Register Link",
  //   alignRight: false,
  // },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberSubscriptions() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memberData, setMemberData] = useState({});
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: " " + memberData?.last_name + " (" + memberData?.email + ") ",
      navigation: null,
      active: true,
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async () => {
    setIsLoading(true);
    const result = await memberSubscriberListingApi(member_id);
    if (result?.code == 200) {
      const members = result?.subscription?.map((event, index) => {
        let event_plan = event?.plan;
        let plan_title = "N/A";
        let created_at = "N/A";
        let affiliate_name = "N/A";
        if (event_plan?.plan_title) {
          plan_title = event_plan?.plan_title;
        }
        let payment_access = event_plan?.payment_access;
        if (plan_title != "N/A") {
          if (event_plan?.is_plan_free) {
            plan_title = plan_title + " (Free)";
          } else if (payment_access == "recursion") {
            plan_title = plan_title + " (Recurring)";
          } else {
            plan_title = plan_title;
          }
        }
        let affiliate = event?.affiliate?.affiliate_user_info;
        if (affiliate && affiliate.first_name) {
          affiliate_name = affiliate.first_name + " " + affiliate.last_name;
        }

        if (event?.createdAt) {
          created_at = moment(event?.createdAt).format("DD-MM-YYYY");
        }
        let statusTab = "Active";
        if (event?.is_completed == true) {
          statusTab = "Completed";
        } else if (event?.cancelation_requested == true) {
          let date = moment(event.subscription_cancelled_date).format(
            "DD MMMM YYYY"
          );
          statusTab = "Cancels at: " + date;
        } else if (event?.is_on_trial == true) {
          let date = moment(event?.trial_ending_date).format("DD MMMM YYYY");
          statusTab = "Trial Ends at: " + date;
        }
        let nextDate = "N/A";
        if (event?.is_completed == true) {
          nextDate = "N/A";
        } else if (event?.is_on_trial == true) {
          let date = moment(event?.trial_ending_date).format("DD MMMM YYYY");
          // date = moment(date, "DD MMMM YYYY")
          //   .add(1, "days")
          //   .format("DD-MM-YYYY");
          nextDate = date;
        } else if (event?.next_payment_date) {
          let date = moment(event?.next_payment_date).format("DD-MM-YYYY");
          nextDate = date;
        }
        return {
          ...event,
          page_name: event?.sale_page?.sale_page_title,
          created_at,
          nextPaymentDate: nextDate,
          // nextPaymentDate: dd_date_format(event.next_payment_date),
          plan_title: plan_title,
          affiliate_name: affiliate_name,
          MENU_OPTIONS:
            event?.cancelation_requested == true ? (
              <>
                <AccessTimeIcon />
              </>
            ) : (
              menuHandling(event)
            ),
          //   MENU_OPTIONS:
          // event?.cancelation_requested == true ? "" : menuHandling(event),
          statusDate:
            event?.is_completed == true
              ? statusTab
              : event?.cancelation_requested == true
              ? statusTab
              : event?.is_on_trial === true
              ? statusTab
              : event.subscription_status === false
              ? "Expired"
              : "Active",
          preview_link_data: {
            to: s3baseUrl + event?.aggrement_pdf_url, // yaha pr check lgaana ha agr page landing ha to slug ni attach krna
            target: "_blank",
            show_text: event?.aggrement_pdf_url ? "Preview" : "",
            className: "anchor-style",
          },
        };
      });
      setSubscriptionsData(members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const handleEdit = (value) => {
    navigate(`/member/edit-member-subscribers/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    console.log(deleteDoc, "deleteDoc");
    setOpenDelete(false);
    setIsLoading(true);
    const result = await memberCancleSubscribergApi(member_id, deleteDoc._id);
    if (result?.code === 200) {
      // setSubscriptionsData((prev) => {
      //   return prev.filter((data) => data._id !== deleteDoc._id);
      // });
      getMembersList();
      enqueueSnackbar(result?.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result?.message, { variant: "error" });
    }
  };
  // testing dev
  const menuHandling = (value) => {
    const MENU_OPTIONS = [];

    if (value?.is_completed) {
    } else {
      if (
        value?.cancelation_requested == false &&
        value?.subscription_status == true
      ) {
        MENU_OPTIONS.push({
          label: "Cancel",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        });
      }
    }

    return MENU_OPTIONS;
  };
  const MENU_OPTIONS_2 = [
    // {
    //   label: "Edit",
    //   icon: "akar-icons:edit",
    //   handleClick: handleEdit,
    // },
    {
      label: "Cancel",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getMembersList();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to cancel this subscription?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}
            {/* <p className="pt-2 ms-2">
              {memberData?.first_name +
                " " +
                memberData?.last_name +
                " (" +
                memberData?.email +
                ") "}
            </p> */}
          </div>
          <div className="col-12 d-flex">
            {memberData?.consultant && (
              <>
                <p className="mt-2 ps-2 title-color"> Delegate: </p>
                <p className="mt-2 ms-2">
                  {" " +
                    memberData?.consultant.first_name +
                    " " +
                    memberData?.consultant.last_name +
                    " (" +
                    memberData?.consultant.email +
                    ")"}
                </p>
              </>
            )}
            {memberData.affliliate && (
              <>
                <p className="mt-2 ps-2 title-color"> | Affiliate: </p>
                <p className="mt-2 ms-2">
                  {memberData.affliliate.affiliate_user_info.first_name +
                    " " +
                    memberData.affliliate.affiliate_user_info.last_name +
                    " (" +
                    memberData.affliliate.affiliate_user_info.email +
                    ")"}
                </p>
              </>
            )}
          </div>
          <div className="col-lg-8 col-sm-12 mb-3 mt-3 d-flex align-items-center">
            <h2>Member Subscriptions</h2>
            {/* <p className="pt-2 ms-2">
              {memberData?.first_name +
                " " +
                memberData?.last_name +
                " (" +
                memberData?.email +
                ") "}
            </p> */}
          </div>
          <div className="col-lg-4 col-sm-12 text-end"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={subscriptionsData}
              // MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
      </div>
    </>
  );
}
