import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText, Input, InputLabel } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AddWorkoutApi } from "src/DAL/workout/workout";
import { convertToTitleCase } from "src/utils/convertHtml";
function AddWorkoutCalenderModel({
  workoutTypes,
  onOpen,
  setOnOpen,
  workoutAsTem,
}) {
  // const [open, setOpen] = useState(onOpen);
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const navigate = useNavigate();
  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    programStatus: "true",
    image: {},
    type: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    video_duration: new Date("00"),
  });
  const handleOpen = () => {
    setProfileImage("");
    // console.log(workoutAsTem, "sdhfkjakjjkda");
    setInputs((inputs) => ({
      ...inputs,
      ["title"]: workoutAsTem?.title,

      ["status"]: true,
      ["type"]: "",
      ["short_description"]: workoutAsTem?.description,
      ["image"]: "",
    }));
  };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleClose = () => {
    // setOpen(false);
    setOnOpen(false);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async () => {
    // Add your logic to save the data (title, description, and status) here
    if (
      inputs.title &&
      inputs.image &&
      inputs.type &&
      inputs.short_description
    ) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);

      formData.append("image", inputs.image);
      formData.append("type", inputs.type);
      formData.append("description", inputs.short_description);
      formData.append("status", inputs.status);
      formData.append("exercises", JSON.stringify(workoutAsTem?.exercises));
      formData.append("practice_type", workoutAsTem?.practice_type);
      if (workoutAsTem.video_url) {
        console.log("hello");
        formData.append("video_url", workoutAsTem.video_url);
        formData.append("video_thumbnail", workoutAsTem.video_thumbnail);
      }

      console.log(...formData, "dskjfhakjdsh", workoutAsTem);

      const result = await AddWorkoutApi(formData);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setOnOpen(false);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      if (!inputs.image) {
        enqueueSnackbar("Please Upload Image", { variant: "error" });
      } else {
        enqueueSnackbar("Please fill all the fields", { variant: "error" });
      }
    }
  };
  useEffect(() => {
    handleOpen();
  }, [onOpen]);
  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      {/* <button className="small-contained-button m-auto" onClick={handleOpen}>
        Add {convertToTitleCase(workoutAsTem?.practice_type)}
      </button> */}
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "65%",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            border: "none",
            overflow: "auto",
            maxHeight: "90vh",
          }}>
          <CloseIcon
            onClick={handleClose}
            sx={{ position: "absolute", top: 3, right: 3 }}
          />
          <h2 id="simple-modal-title">
            Add {convertToTitleCase(workoutAsTem?.practice_type)} to library
          </h2>

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              {" "}
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label={`${convertToTitleCase(
                    workoutAsTem?.practice_type
                  )} Name*`}
                  variant="outlined"
                  fullWidth
                  name="title"
                  value={inputs.title}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  {convertToTitleCase(workoutAsTem?.practice_type)} Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  value={inputs.type}
                  label={`${convertToTitleCase(
                    workoutAsTem?.practice_type
                  )} Type *`}
                  onChange={handleChange}>
                  {workoutTypes.map((item) => {
                    // console.log(item, "sdkjfjsd");
                    return (
                      <MenuItem value={item._id} key={item._id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  {convertToTitleCase(workoutAsTem?.practice_type)} Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="lesson Status *"
                  onChange={handleChange}>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Short Description* "
                  multiline
                  rows={7}
                  name="short_description"
                  value={inputs.short_description}
                  onChange={handleChange}
                />
                <FormHelperText sx={{ fontSize: 10 }}>
                  (Maximum limit 250 characters)
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className=" my-3">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0" sx={{ fontSize: 10 }}>
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file && (
                  <img className="image-border" src={file} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    required
                    onChange={fileChangedHandler}
                    style={{ display: "none" }}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                    variant="contained"
                    size="small">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
          {/* <Button variant="contained" type="submit" sx={{ mr: 2 }}>
              START BUILDING
            </Button> */}
          <button
            className="small-contained-button mt-3"
            type="submit"
            disabled={isloading}
            onClick={handleSubmit}>
            save
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default AddWorkoutCalenderModel;
