import { invokeApi } from "../../bl_libs/invokeApi";

export const add_videos_Category = async (data) => {
  const requestObj = {
    path: `/api/helping_videos_category/add_helping_video_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const helping_video_Category_listing_api = async (data) => {
  const requestObj = {
    path: `/api/helping_videos_category/list_helping_video_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const videos_category_detail = async (data) => {
  const requestObj = {
    path: `/api/helping_videos_category/detail_helping_video_category/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_video_category_api = async (data, slug) => {
  const requestObj = {
    path: `/api/helping_videos_category/update_helping_video_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_videos_category = async (slug) => {
  const requestObj = {
    path: `/api/helping_videos_category/delete_helping_video_category/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
