import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Button,
  FormControl,
  Typography,
  FormGroup,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Checkbox,
  Radio,
  Backdrop,
  CircularProgress,
  Divider,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AvatarGalleryPreview from "src/components/imageViewOnLengthPreview";
import { getPreviewDetail } from "src/DAL/SendFormTemplate/sendFormTemplate";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DetailsFormInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const location = useLocation();
  const classes = useStyles();

  const { state } = useLocation();
  const param = useParams();
  const _id = param.slug;
  const [templateTitle, setTemplateTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [privateNote, setPrivateNote] = useState("");
  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeText = (e, index) => {
    questions[index].answer = e.target.value;
    setQuestions([...questions]);
  };
  const handleChangeDescription = (e, index) => {
    questions[index].description = e.target.value;
    setQuestions([...questions]);
  };

  const getFormDetails = async () => {
    try {
      setIsLoading(true);
      const result = await getPreviewDetail(_id);
      if (result.code === 200) {
        setTemplateTitle(state);
        setQuestions(result?.form?.questions);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getFormDetails();
  }, [state]);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
      >
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>{" "}
      <>
        <form autoComplete="off">
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            justifyContent="space-between"
            mb={4.5}
          >
            <h2 className="text-start w-100">{templateTitle?.title}</h2>
            <Typography
              className="text-start w-100"
              sx={{ fontWeight: "500", fontSize: 16, opacity: 0.6 }}
            >
              {templateTitle?.description}
            </Typography>

            {questions &&
              questions.map((item, index) => {
                return (
                  <Card
                    className="w-100"
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      mb: 1,
                      width: { sm: "100%", md: "100%" },
                      margin: "auto",
                      p: 2,
                      backgroundColor: "",
                      borderRadius: 0.5,
                    }}
                  >
                    <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                        mt={1}
                      >
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                              >
                                Q{index + 1}.
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: 14, mr: 1 }}
                              >
                                {item?.title}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        {item?.type == "multiple_choice" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={item?.answer}
                                onChange={(e) => {
                                  questions[index].answer = e.target.value;
                                  setQuestions([...questions]);
                                }}
                              >
                                {item?.options.map((item) => {
                                  return (
                                    <Grid item md={12}>
                                      <FormControlLabel
                                        value={item}
                                        disabled
                                        control={
                                          <Radio
                                            sx={{
                                              "&.Mui-disabled": {
                                                color: "#212B36", // Color for disabled checkbox
                                              },
                                              "&.Mui-checked": {
                                                color: "#0096FF", // Color for checked checkbox
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <span style={{ color: "#212B36" }}>
                                            {item}
                                          </span>
                                        }
                                      />
                                    </Grid>
                                  );
                                })}{" "}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "multiple_select" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <FormGroup>
                                {item?.options.map((option) => (
                                  <Grid item md={12} key={option}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          sx={{
                                            "&.Mui-disabled": {
                                              color: "#212B36", // Color for disabled checkbox
                                            },
                                            "&.Mui-checked": {
                                              color: "#0096FF", // Color for checked checkbox
                                            },
                                          }}
                                          disabled
                                          checked={questions[
                                            index
                                          ]?.multiple_answers.includes(option)}
                                        />
                                      }
                                      label={
                                        <span style={{ color: "#212B36" }}>
                                          {option}
                                        </span>
                                      }
                                    />
                                  </Grid>
                                ))}
                              </FormGroup>
                            </FormControl>
                            {/* */}
                          </Grid>
                        ) : item?.type == "image" ? (
                          <Grid item md={12} xs={12}>
                            <div className="d-flex align-items-center mb-2">
                              {/* {console.log(item.answer, "dsfsdfsdfasdfadsfa")} */}
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    mr: 1,
                                  }}
                                >
                                  {item?.options[0]}
                                </Typography>
                              </div>

                              <AvatarGalleryPreview
                                length={item?.options_count}
                                Image={item?.multiple_answers}
                              />
                            </div>
                          </Grid>
                        ) : item?.type == "free_text" ? (
                          <Grid item md={12} xs={12}>
                            <Typography
                              sx={{
                                fontSize: 14,

                                mb: 0,
                                width: "100%",
                              }}
                            >
                              {item?.answer && (
                                <span>
                                  <b>
                                    <i>Answer: </i>
                                  </b>
                                </span>
                              )}
                              {item?.answer ? item?.answer : "No Answer"}
                            </Typography>
                            {/* <TextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                  disabled
                                rows={2}
                                value={item?.description}
                                sx={{ width: "100%", mt: 2 }}
                                onChange={(e) =>
                                  handleChangeDescription(e, index)
                                }
                              /> */}
                          </Grid>
                        ) : (
                          item?.type == "signature" && (
                            <Grid item md={12} xs={12}>
                              {item?.answer ? (
                                <img
                                  key={index}
                                  src={item?.answer}
                                  alt={`Signature ${index + 1}`}
                                />
                              ) : (
                                <TextField
                                  id="outlined-multiline-static"
                                  multiline
                                  disabled
                                  rows={4}
                                  sx={{
                                    width: "100%",
                                    "& fieldset": {
                                      top: 0,
                                    },
                                    "& legend": {
                                      display: "none",
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Card>
                );
              })}
          </Stack>
        </form>
      </>
    </Container>
  );
}
