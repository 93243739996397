import { invokeApi } from "../../bl_libs/invokeApi";

export const website_plan_category_list_api = async (id) => {
  let api_path = `/api/plan_category/list_plan_category`;
  if (id) {
    api_path = `/api/plan_category/list_plan_category/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const website_plan_against_category_list_api = async (id) => {
  const requestObj = {
    path: `/api/plan_category/list_plans_in_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const website_active_plan_category_list_api = async (id) => {
  let api_path = `/api/plan_category/active_plan_category`;
  if (id) {
    api_path = `/api/plan_category/list_plan_category/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_plan_category_api = async (data) => {
  const requestObj = {
    path: `/api/plan_category/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_plan_category_api = async (data, id) => {
  const requestObj = {
    path: `/api/plan_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const website_plan_category_detail_api = async (id) => {
  const requestObj = {
    path: `/api/plan_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_plan_category_api = async (id) => {
  const requestObj = {
    path: `/api/plan_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const remove_plan_against_category_api = async (id, data) => {
  const requestObj = {
    path: `/api/plan_category/remove_plans_from_category/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
