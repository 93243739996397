import * as React from "react";

import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import moment from "moment";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";

import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { addForm, editForm } from "src/DAL/SendFormTemplate/sendFormTemplate";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function EditForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const [allTemplates, setAllTemplates] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [choachesName, setChoachesName] = React.useState([]);

  const [member, setMember] = React.useState([]);
  const [singleMember, setSingleMember] = React.useState(null);

  const [singleChoachesName, setSingleChoachesName] = useState(null);

  const [inputs, setInputs] = useState({
    date: moment().format("YYYY-MM"),
    status: true,
    leave_reason: "",
    description: "",
    title: "",
    person: "",
    form_template: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      title: inputs?.title,
      form_template: inputs?.form_template,
      description: inputs?.description,
      status: inputs?.status,
    };

    setIsLoading(true);
    try {
      const res = await editForm(state.form_slug, postData);
      if (res.code === 200) {
        navigate(-1);

        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getFormListing = async () => {
    setIsLoading(true);
    try {
      const result = await getAllTemplatesListByType(state?.receiver_type);
      if (result.code === 200) {
        setAllTemplates(result?.form_templates);
        setInputs((value) => ({
          ...value,
          title: state.title,
          form_template: state.form_template,
          description: state.description,
          status: state?.status,
        }));
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getFormListing();
  }, [state]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Edit Form</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            sx={{ width: "100%" }}
            // fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-selec-label">Form Template*</InputLabel>
            <Select
              required
              labelId="demo-simple-selec-label"
              id="demo-simple-select"
              name="form_template"
              value={inputs?.form_template}
              label="Form Template*"
              onChange={handleChange}
            >
              {allTemplates.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status*</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs?.status}
              label="Status*"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Description"
            multiline
            variant="outlined"
            sx={{ width: "100%" }}
            // fullWidth
            rows={4}
            required
            name="description"
            value={inputs.description}
            onChange={(e) => {
              if (e.target.value[0] === " ") {
                return;
              }
              handleChange(e);
            }}
          />
        </div>
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
