import ImageGallery from "react-image-gallery";
const FeedImageSlider = ({ data, autoPlay, selectedIndex }) => {
  return (
    <div className="image-slider">
      <ImageGallery
        items={data}
        autoPlay={autoPlay ? autoPlay : false}
        showFullscreenButton={false}
        showPlayButton={false}
        slideInterval={3000}
        startIndex={selectedIndex}
      />
    </div>
  );
};

export default FeedImageSlider;
