import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import { AddLessonApi } from "src/DAL/lessons/lessons";
import { TimePicker } from "@mui/lab";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { AddExerciseApi } from "src/DAL/exercise/exercise";
import { AllActiveExerciseTypeListingApi } from "src/DAL/exerciseType/exerciseType";
import TagesToShow from "./component/modalToshowTages";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AddExercise() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [exerciseTags, setExerciseTags] = useState([]);
  const [difficultyTags, setDifficultyTags] = useState([]);
  const [mainMuscleTags, setMainMuscleTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [fileGif, setProfileImageGif] = React.useState();
  const [exerciseType, setExerciseType] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  //   const [value, setValue] = React.useState(new Date());

  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    programStatus: "true",
    image: {},
    type: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    upload_type: "video",
    gif: "",
    video_duration: new Date("00"),
  });

  const handleChangeTags = (exerciseTags, difficultyTags, mainMuscleTags) => {
    setExerciseTags(exerciseTags);
    setDifficultyTags(difficultyTags);
    setMainMuscleTags(mainMuscleTags);
    setOpenDetails(false);
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const fileChangedGif = (e) => {
    setProfileImageGif(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["gif"]: e.target.files[0],
    });
  };

  const handleDetails = (value) => {
    setOpenDetails(true);
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.type) {
      enqueueSnackbar("Please select exercise type", { variant: "error" });
      return;
    }
    if (inputs.upload_type == "video") {
      if (urlPatternValidation(inputs.videoUrl) === false) {
        enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
        return;
      }
    }

    if (inputs.upload_type == "gif") {
      if (!inputs.gif) {
        enqueueSnackbar("Upload Gif", { variant: "error" });
        return;
      }
    }

    if (!file) {
      enqueueSnackbar("Upload Image", { variant: "error" });
      return;
    }

    const postTags = {
      difficulty: difficultyTags,
      main_muscle: mainMuscleTags,
      exercise: exerciseTags,
    };
    const formData = new FormData();
    formData.append("title", inputs.title);

    if (file) {
      formData.append("image", inputs.image);
    }
    if (fileGif) {
      formData.append("gif", inputs.upload_type == "gif" ? inputs.gif : "");
    }
    formData.append("type", inputs.type);
    formData.append("instructions", inputs.short_description);
    formData.append("status", inputs.status);
    formData.append(
      "video_url",
      inputs.upload_type == "video" ? inputs.videoUrl : ""
    );
    formData.append("tags", JSON.stringify(postTags));
    //console form data

    console.log(...formData, "form data value", postTags);

    setIsLoading(true);
    const result = await AddExerciseApi(formData);
    if (result.code === 200) {
      //console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      //console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
    // }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getExerciseTypes = async () => {
    try {
      const result = await AllActiveExerciseTypeListingApi();
      if (result.code === 200) {
        console.log(result, "dfkjsdahaf");
        setExerciseType(result?.exercise_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getExerciseTypes();
  }, []);
  React.useEffect(() => {}, []);
  //   //console.log(typeof audioFile, "values of audio to be send ");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <TagesToShow
        open={openDetails}
        setOpen={setOpenDetails}
        handleOk={handleChangeTags}
        exerciseTags={exerciseTags}
        difficultyTags={difficultyTags}
        mainMuscleTags={mainMuscleTags}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Add Exercise</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Exercise Name"
              variant="outlined"
              fullWidth
              name="title"
              required
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Exercise Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="type"
                value={inputs.type}
                label="Exercise Type *"
                onChange={handleChange}
              >
                {exerciseType.map((item) => {
                  // console.log(item, "sdkjfjsd");
                  return (
                    <MenuItem value={item._id} key={item._id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Exercise Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="lesson Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Upload Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="upload_type"
                value={inputs.upload_type}
                label="Upload Type *"
                onChange={handleChange}
              >
                <MenuItem value="video">Video URL</MenuItem>
                <MenuItem value="gif">GIF</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs?.upload_type == "video" ? (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Landing Exercise Video Url "
                variant="outlined"
                fullWidth
                required
                name="videoUrl"
                value={inputs.videoUrl}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <p className="">Upload Gif *</p>
                  <FormHelperText className="pt-0">
                    Gif Size(1000 X 670)
                  </FormHelperText>
                </div>
                <div className="col-lg-2 col-md-2 col-6">
                  {fileGif && (
                    <img className="image-border" src={fileGif} height="50" />
                  )}
                </div>
                <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                  <label htmlFor="contained-button-file-1">
                    <Input
                      accept="image/GIF"
                      id="contained-button-file-1"
                      // multiple
                      type="file"
                      name="gif"
                      onChange={fileChangedGif}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs.gif.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs.gif.name}</p>
              )}
            </div>
          )}

          {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {audioFile && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
        </div> */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-lg-2 col-md-2 col-6">
                {file && (
                  <img className="image-border" src={file} height="50" />
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                <label htmlFor="contained-button-file">
                  {/* <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp, .gif"
                  onChange={handleOtherImageFileChange}
                /> */}
                  <Input
                    accept=".jpg, .jpeg, .png, .webp"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
          <div className="col-12 mt-3 d-flex align-items-start">
            <div> Tags:</div>
            <Box sx={{ marginTop: 0 }}>
              {exerciseTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
              {mainMuscleTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
              {difficultyTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
              <Button
                variant="text"
                size="small"
                sx={{
                  p: 0,
                  backgroundColor: "transparent",
                  ml: 2,
                  textTransform: "inherit",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => handleDetails()}
              >
                Add tags +
              </Button>
            </Box>
          </div>
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Instructions"
                multiline
                rows={6}
                required
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          {/* <div className="col-12 mt-5">
          <h4>instructions *</h4>
          <TinyEditor
            setDetailDescription={setDetailDescriptionCk}
            detailDescriptionCk={detailDescriptionCk}
          />
        </div> */}

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
