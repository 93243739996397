import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import { CircularProgress, Chip, IconButton } from "@mui/material";
//
import {
  admin_videos_listing,
  helpingVideoAgainstCategory,
} from "src/DAL/HelpingVideos/AdminHelpingVideos";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RecordNotFound from "src/components/RecordNotFound";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AdminVideos() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [adminVideos, setAdminVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const programsList = async () => {
    const postData = {
      search: "",
    };
    const result = await helpingVideoAgainstCategory(postData, id);
    if (result.code == 200) {
      setIsLoading(false);
      console.log("jksdfkgjks", result);
      setAdminVideos(result.helping_videos);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(
      `/coaches-videos/coach-videos-detail/${value.helping_video_slug}`,
      {
        state: { detailValues: value },
      }
    );
  };
  useEffect(() => {
    programsList();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate("/coaches_videos_category")}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>Coach Helping videos </h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/coaches-videos/add-coach-videos/${id}`)}
            className="small-contained-button"
          >
            Add Coach Videos
          </button>
        </div>
        {adminVideos.length > 0 ? (
          adminVideos.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value.thumbnail_image.thumbnail_1}
                    className="card-img-top pods-image"
                    alt=""
                    onClick={() => handleNavigateDetail(value)}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="h2-heading fw-bold fs-5"
                          onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(value.title)}
                        </div>
                        {!value?.status && (
                          <Chip
                            label={"Inactive"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <p className="programme-card-desc">
                      {htmlDecode(value.short_description)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <RecordNotFound title="Videos" />
        )}
      </div>
    </div>
  );
}
