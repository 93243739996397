import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Radio,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function ImageView({ rating, setRating }) {
  return (
    <Grid item md={12} xs={12}>
      <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Image Limit:
          </Typography>
        </div>
        <div>
          <FormControl sx={{ mb: 0, width: "100%" }} size="small">
            <Select
              required
              labelId="demo-simple-selec-label"
              id="demo-simple-select"
              name="question_type"
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {/* <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Minimum Lable:
          </Typography>
        </div>
        <div className="w-50">
          <TextField
            size="small"
            id="outlined-basic"
            label=""
            variant="outlined"
            sx={{
              width: "100%",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            // fullWidth
            required
            name="question_statement"
            placeholder="Enter a label for Minimum (please specify)"
            value={minimumRating}
            onChange={(e) => {
              if (e.target.value[0] === " ") {
                return;
              }
              setMinimumRating(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div className="rating-lables">
          <Typography sx={{ fontWeight: "500", fontSize: 14, mr: 1 }}>
            Maximum Lable:
          </Typography>
        </div>
        <div className="w-50">
          <TextField
            size="small"
            id="outlined-basic"
            label=""
            variant="outlined"
            sx={{
              width: "100%",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            // fullWidth
            required
            name="question_statement"
            placeholder="Enter a label for Maximum (please specify)"
            value={maximumRating}
            onChange={(e) => {
              if (e.target.value[0] === " ") {
                return;
              }
              setMaximumRating(e.target.value);
            }}
          />
        </div>
      </div> */}
    </Grid>
  );
}
