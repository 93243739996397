import React from "react";
import ReactEditor from "react-text-editor-kit";
import { useSnackbar } from "notistack";

function TextEditor({
  inputs = {}, // Default to an empty object
  setInputs,
  name = "", // Default to an empty string
  is_array = false, // Default to false
  index = 0, // Default to 0
  value = "", // Default to an empty string
  className = "", // Default to an empty string
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (newValue) => {
    if (is_array && inputs[index]) {
      // Check if inputs[index] exists
      const list = [...inputs];
      list[index][name] = newValue;
      setInputs(list);
    } else if (inputs && name) {
      // Update the specified input field if available
      setInputs((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      // Set the new value directly if inputs and name are not applicable
      setInputs(newValue);
    }
  };

  const editorValue = () => {
    let valueData = "";
    if (is_array && inputs[index] && inputs[index][name]) {
      // Only access inputs[index][name] if inputs[index] is defined
      valueData = inputs[index][name];
    } else if (inputs[name]) {
      valueData = inputs[name];
    } else {
      valueData = value; // Use provided value if inputs[name] is undefined
    }
    return valueData;
  };

  return (
    <div className={`App ${className}`}>
      <ReactEditor
        className={`custom-editor ${className}`}
        value={editorValue()} // Get initial value for the editor
        onChange={handleChange} // Handle changes
        placeholder="Write your text here"
        style={{ textAlign: "start", height: "500px" }} // Adjust height here
      />
    </div>
  );
}

export default TextEditor;
