import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExerciseTagesApi } from "src/DAL/exerciseType/exerciseType";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));
function TagesToShow({
  open,
  setOpen,
  handleOk,
  exerciseTags,
  difficultyTags,
  mainMuscleTags,
}) {
  const handleCloseDialog1 = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const [selectedExerciseTags, setSelectedExerciseTags] = useState([]);
  const [selectedDifficultyTags, setSelectedDifficultyTags] = useState([]);
  const [selectedMainMuscleTags, setSelectedMainMuscleTags] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [main_muscle, setMainMuscle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleTagMainMuscleToggle = (tag) => () => {
    const newSelectedTags = [...selectedMainMuscleTags];
    const index = newSelectedTags.indexOf(tag);

    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }

    setSelectedMainMuscleTags(newSelectedTags);
  };
  const handleTagDifficultyToggle = (tag) => () => {
    const newSelectedTags = [...selectedDifficultyTags];
    const index = newSelectedTags.indexOf(tag);

    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }

    setSelectedDifficultyTags(newSelectedTags);
  };
  const handleTagToggle = (tag) => () => {
    const newSelectedTags = [...selectedExerciseTags];
    const index = newSelectedTags.indexOf(tag);

    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }

    setSelectedExerciseTags(newSelectedTags);
  };
  useEffect(() => {
    setSelectedExerciseTags(exerciseTags);
    setSelectedDifficultyTags(difficultyTags);
    setSelectedMainMuscleTags(mainMuscleTags);
  }, [open]);
  const getExerciseTagesListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = await ExerciseTagesApi();
      if (result.code === 200) {
        console.log(result, "tages");
        setExercise(result?.tags?.exercise);
        setDifficulty(result?.tags?.difficulty);
        setMainMuscle(result?.tags?.main_muscle);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getExerciseTagesListing();
  }, [open]);
  return (
    <>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        onClose={handleCloseDialog1}
        scroll="body"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>Manage Exercise Tags</DialogTitle>
        {isLoading ? (
          <CircularProgress className={classes.loading} color="primary" />
        ) : (
          <DialogContent dividers={scroll === "paper"}>
            <div className="d-flex justify-content-around">
              <div>
                <FormGroup>
                  Exercise tags
                  {exercise.map((tag) => (
                    <Box
                      key={tag}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        size="small"
                        checked={selectedExerciseTags.includes(tag)}
                        onChange={handleTagToggle(tag)}
                        sx={{ padding: "5px" }}
                      />
                      <FormControlLabel
                        control={
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {tag}
                          </Typography>
                        }
                        label=""
                      />
                    </Box>
                  ))}
                </FormGroup>
              </div>
              <div>
                {" "}
                <FormGroup>
                  Main Muscle(s)
                  {main_muscle.map((tag) => (
                    <Box
                      key={tag}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        size="small"
                        checked={selectedMainMuscleTags.includes(tag)}
                        onChange={handleTagMainMuscleToggle(tag)}
                        sx={{ padding: "5px" }}
                      />
                      <FormControlLabel
                        control={
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {tag}
                          </Typography>
                        }
                        label=""
                      />
                    </Box>
                  ))}
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  Difficulty
                  {difficulty.map((tag) => (
                    <Box
                      key={tag}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        size="small"
                        checked={selectedDifficultyTags.includes(tag)}
                        onChange={handleTagDifficultyToggle(tag)}
                        sx={{ padding: "5px" }}
                      />
                      <FormControlLabel
                        control={
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {tag}
                          </Typography>
                        }
                        label=""
                      />
                    </Box>
                  ))}
                </FormGroup>
              </div>
            </div>
          </DialogContent>
        )}

        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog1}>
            close
          </Button>
          <Button
            className="model-button-hover"
            onClick={() =>
              handleOk(
                selectedExerciseTags,
                selectedDifficultyTags,
                selectedMainMuscleTags
              )
            }
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TagesToShow;
