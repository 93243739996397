import { invokeApi } from "../../../bl_libs/invokeApi";

export const delete_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/member/delete_dynamite_event_member/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const dynamiteEventsMembersDetailApi = async (data) => {
  const requestObj = {
    path: `/api/member/get_live_dynamite_event_member_detail/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/member/add_dynamite_event_member/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/member/edit_dynamite_event_member/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const dynamiteEventsMembersApi = async (
  event_id,
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `/api/member/get_live_dynamite_event_member_by_pagination/${event_id}?search_text=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
// export const dynamiteEventsMembersApi = async (event_id) => {
//   const requestObj = {
//     path: `/api/member/get_live_dynamite_event_member/${event_id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     // postData: data,
//   };
//   return invokeApi(requestObj);
// };
